import { FormControl, Input, InputLabel, List, ListItem, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { PlaceSession } from '../../../../../Api/Business/PlaceSession';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { useManagerMenuCardsContext } from '../../context/menu-card/ManagerMenuCardsContext';

interface SessionEditorProps
{
	onSavePlaceSession: (placeSession: PlaceSession, endSession?: boolean) => void;
	placeSession: PlaceSession;
}

export const SessionEditor: FC<SessionEditorProps> =
	observer(
		({
			 onSavePlaceSession,
			 placeSession,
		 }) =>
		{
			const {localizer} = useContext(EntranceContextRef);
			const {menuCards} = useManagerMenuCardsContext();

			const additionalMenuCards = useMemo(() => {
				const placeAssignedMenuCardIds = placeSession.place.menuCardIds;

				if (placeAssignedMenuCardIds === undefined)
					return menuCards;
				else
					return menuCards
						?.filter(menuCard => placeAssignedMenuCardIds.indexOf(menuCard.id) === -1);
			}, [menuCards, placeSession.place.menuCardIds]);

			const setNumberOfCovers = useCallback(
				(covers: number) =>
				{
					if (placeSession)
					{
						placeSession.numberOfCovers = covers;
					}
				},
				[placeSession]);

			const setMenuCard = useCallback(
				(menuCardId?: number) =>
				{
					if (placeSession)
					{
						if (menuCards && menuCardId)
						{
							placeSession.menuCardIds = [menuCardId];
						}
						else
						{
							placeSession.menuCardIds = [];
						}
					}
				},
				[menuCards, placeSession]);

			return <List>
				<ListItem>
					<TextField
						fullWidth
						label={
							<Typography>
								{localizer.translate('Place-Session-Covers-Amount')}
							</Typography>
						}
						type="number"
						defaultValue={placeSession.numberOfCovers}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						{
							let value = event.target.valueAsNumber;

							setNumberOfCovers(isNaN(value) ? 0 : value);
						}}
						onKeyPress={(event: React.KeyboardEvent) =>
						{
							if (event.key === 'Enter')
							{
								onSavePlaceSession(placeSession);
							}
						}}
					/>
				</ListItem>
				{
					additionalMenuCards !== undefined &&
					additionalMenuCards.length > 0 &&
					<ListItem>
						<FormControl
							fullWidth
						>
							<InputLabel
								shrink
								htmlFor="extra-menu-card-label"
							>
								{localizer.translate('Place-Session-Menu-Extra')}
							</InputLabel>
							<Select
								input={
									<Input
										name={'Extra menukaart'}
										id="extra-menu-card-label"
									/>
								}
								onChange={event =>
								{
									const value = parseInt(event.target.value as string, 10);

									if (!isNaN(value))
									{
										setMenuCard(value === -1 ? undefined : value);
									}
								}}
								value={placeSession.menuCardIds[0] ?? -1}
							>
								<MenuItem
									value={-1}
								>
									{localizer.translate('Generic-None')}
								</MenuItem>
								{
									additionalMenuCards.map(
										menuCard =>
											<MenuItem
												key={menuCard.id}
												value={menuCard.id}
											>
												{menuCard.name}
											</MenuItem>)
								}
							</Select>
						</FormControl>
					</ListItem>
				}
			</List>;
		});
