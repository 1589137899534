import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class OrderDestinationAddress
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	street: string;
	number: string;
	line2?: string;
	name?: string;
	companyName?: string;
	postalCode?: string;
	city: string;
	stateOrProvince?: string;

	/**
	 * Country code, ISO-3166-1 alpha-2
	 */
	country: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public get singleLineValue()
	{
		const {
			street,
			number,
			postalCode,
			city
		} = this;
		return (street ?? '')
			+ ((street && number) ? ` ${number}` : '')
			+ (((street) && (postalCode || city)) ? ', ' : '')
			+ (postalCode ?? '')
			+ ((postalCode && city) ? ' ' : '')
			+ (city ?? '');
	}
}

export const OrderDestinationAddressProfile =
	SerializationProfile.create(OrderDestinationAddress);
