import { isWidthUp } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useRootContext } from '../../../../RootContext';
import { useIsKioskMode } from '../../../../Service/KioskService/Api/useIsKioskMode';
import { useComputed } from '../../../../Util/Hooks/useComputed';
import { useScreenWidth } from '../../../../Util/Hooks/useScreenWidth';
import BrandedPage from '../../../UI/BrandedPage';
import { CenteredPageContent } from '../../../UI/CenteredPageContent';
import { topBarHeight, topBarHeightMobile } from '../../../UI/PageTopBar';
import { SearchContextProvider } from '../../../UI/Search/SearchContext';
import { SessionStatusBar } from '../../../UI/session-status-bar/SessionStatusBar';
import { drawerWidth } from '../BusinessConstants';
import { BusinessContextRef } from '../BusinessContext';
import { MenuContent } from './MenuContent';
import { MenuStore } from './MenuStore';
import { MenuTopBar } from './MenuTopBar';
import { MenuSearchCard } from './Search/MenuSearchCard';
import { MenuSearchSpacer } from './Search/MenuSearchSpacer';

const useStyle = makeStyles<Theme, { isAtLeastMediumSizedWindow: boolean, shiftContent: boolean }>(theme => ({
	contentRoot: ({shiftContent}) => ({
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		marginRight: shiftContent ? drawerWidth : 0,

		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	page: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	searchCard: ({isAtLeastMediumSizedWindow, shiftContent}) => ({
		borderRadius: 0,
		height: isAtLeastMediumSizedWindow ? topBarHeight : topBarHeightMobile,
		paddingRight: shiftContent ? drawerWidth : 0,

		transition: theme.transitions.create('padding-right', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	searchCardRoot: {
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		width: '100%',
		zIndex: theme.zIndex.appBar,
	},
}));

interface MenuProps
{
	onStartPayingBill: () => void;
	openNavigationMenu?: () => void;
	store: MenuStore;
	embedded?: boolean;
}

export const Menu: FC<MenuProps> =
	(
		{
			onStartPayingBill,
			openNavigationMenu,
			store,
			embedded,
		},
	) =>
	{
		const {brandingService} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);
		const [isSearchingMenu, setIsSearchingMenu] = useState(false);
		const [menuSearchQuery, setMenuSearchQuery] = useState<string | undefined>();

		useEffect(() => {
			if (!isSearchingMenu)
				setMenuSearchQuery(undefined);
		}, [isSearchingMenu]);

		const isKioskMode = useIsKioskMode();
		const cartNonEmpty = useObserver(() => !store.shoppingCartStore.isEmpty);
		const useSecondaryColor = useObserver(() => brandingService.shouldUseSecondaryColorOnMenu);
		const timeSchedules = useObserver(() => businessStore.timeSchedules);

		const screenWidth = useScreenWidth();
		const isAtLeastMediumSizedWindow = useMemo(() => isWidthUp('md', screenWidth), [screenWidth]);

		const shiftContent = useMemo(
			() => !isKioskMode && cartNonEmpty && isAtLeastMediumSizedWindow && !embedded,
			[cartNonEmpty, embedded, isAtLeastMediumSizedWindow, isKioskMode],
		);

		const classes = useStyle({isAtLeastMediumSizedWindow, shiftContent});

		const showTopBar =
			useMemo(
				() => !isSearchingMenu && (!store.entranceStore.isTopBarHidden.get() || store.parentMenuStore),
				[isSearchingMenu, store]
			);

		const content = useMemo(() => {
			if (timeSchedules !== undefined)
				return <MenuContent
					onStartPayingBill={onStartPayingBill}
					store={store}
					embedded={embedded}
				/>;
		}, [embedded, onStartPayingBill, store, timeSchedules]);

		const hasOnlyTiles =
			useComputed(
				() =>
				{
					if (isKioskMode)
					{
						return false;
					}
					else
					{
						return store.category.products.every(product => !product.menuEntryType || product.menuEntryType === 'TILE')
							&& store.category.categories.every(category => !category.menuEntryType || category.menuEntryType === 'TILE');
					}
				},
				[isKioskMode, store]
			);

		const contentWrapper = useMemo(() => {
			if (isAtLeastMediumSizedWindow)
				return <div 
					className={classes.contentRoot}
				>
					{/* We disable the flex inside the CenteredPageContent due to the carousels not rendering correctly (they grow infinitely) */}
					<CenteredPageContent
						stretchedHeight={hasOnlyTiles}
						centeredContent={hasOnlyTiles}
					>
						{content}
					</CenteredPageContent>
				</div>;
			else
				return content;
		}, [classes.contentRoot, content, hasOnlyTiles, isAtLeastMediumSizedWindow]);

		const topBar = useMemo(() => {
			if (!showTopBar)
				return undefined;

			return <MenuTopBar store={store} openNavigationMenu={openNavigationMenu} />;
		}, [openNavigationMenu, showTopBar, store]);

		const searchBar = useMemo(() => {
			if (!isSearchingMenu)
				return undefined;

			return <MenuSearchCard
				classes={{
					card: classes.searchCard,
					root: classes.searchCardRoot,
				}}
				useSecondaryColor={useSecondaryColor}
			/>;
		}, [classes.searchCard, classes.searchCardRoot, isSearchingMenu, useSecondaryColor]);

		const footer = useMemo(() => <SessionStatusBar/>, []);

		return <SearchContextProvider
			value={{
				isSearching: isSearchingMenu,
				setIsSearching: setIsSearchingMenu,
				setQuery: setMenuSearchQuery,
				query: menuSearchQuery,
			}}
		>
			{
				embedded
					? <>
						{contentWrapper}
					</>
					: <>
						<BrandedPage
							brandingService={brandingService}
							className={classes.page}
						>
							<MenuSearchSpacer />
							{contentWrapper}
						</BrandedPage>
						{topBar}
						{searchBar}
						{footer}
					</>
			}
		</SearchContextProvider>;
	};
