import { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { StoryWithPosts } from '../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryLoadingContext } from '../../context/StoryLoadingContext';
import { StoryPlayerContext } from './StoryPlayerContext';

interface StoryPlayerContextProviderProps
{
	story: StoryWithPosts;
	storyIdx: number;
	initialOpenPostIdx?: number;
	active: boolean;
	onClose: () => void;
}

export const StoryPlayerContextProvider: FC<PropsWithChildren<StoryPlayerContextProviderProps>> =
	({
		children,
		story,
		storyIdx,
		initialOpenPostIdx = 0,
		active,
		onClose,
	}) =>
	{
		const {didLoadPost, loadPost, loadPostAndAdjacentPosts} = useContext(StoryLoadingContext);

		const [playedPercentage, setPlayedPercentage] = useState(0);

		const [openPostIdx, setOpenPostIdx] = useState(initialOpenPostIdx);

		const activeRef = useRef(active);

		useEffect(
			() =>
			{
				if (activeRef.current)
				{
					// noinspection JSIgnoredPromiseFromCall
					loadPostAndAdjacentPosts(story, openPostIdx);
				}
				else
				{
					// noinspection JSIgnoredPromiseFromCall
					loadPost(story, openPostIdx);
				}
			},
			[loadPost, loadPostAndAdjacentPosts, openPostIdx, story, storyIdx],
		);

		const onChangeOpenPostIdx = useCallback(
			(value: number | undefined) =>
			{
				if (value === undefined)
				{
					onClose();
				}
				else
				{
					setOpenPostIdx(value);
				}
			},
			[onClose],
		);

		const didLoad = useCallback(
			(postIdx: number) =>
				didLoadPost(story, postIdx),
			[didLoadPost, story],
		);

		const contextValue = useMemo(() => ({
			openPostIdx,
			onChangeOpenPostIdx,
			playedPercentage,
			onChangePlayedPercentage: setPlayedPercentage,
			didLoad,
		}), [didLoad, onChangeOpenPostIdx, openPostIdx, playedPercentage]);

		return <StoryPlayerContext.Provider
			value={contextValue}
		>
			{children}
		</StoryPlayerContext.Provider>;
	};
