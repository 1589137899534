import { Account } from '../../../Api/Account/Account';
import { Client } from '../../../Bridge/Client/Client';
import { Storage } from '../../../Bridge/Storage/Storage';
import { QueryParameters } from '../../../Constants/QueryParameters';
import { getAccountFromStorage } from '../getAccountFromStorage';
import { registerAccountV2 } from './registerAccountV2';
import { verifyAccountV2 } from './verifyAccountV2';

export async function authenticateV2(client: Client, storage: Storage, queryParameters: URLSearchParams, account?: Account): Promise<Account>
{
	const effectiveAccount = account ?? getAccountFromStorage(storage);

	if (effectiveAccount === undefined)
	{
		const userClaimToken = queryParameters.get(QueryParameters.UserClaimToken) ?? undefined;

		return registerAccountV2(client, userClaimToken)
			.catch((error) =>
			{
				if (userClaimToken !== undefined)
					return registerAccountV2(client)
				else
					throw error;
			});
	}
	else
	{
		const isVerified = await verifyAccountV2(client, effectiveAccount);

		return isVerified
			? Promise.resolve(effectiveAccount)
			: registerAccountV2(client);
	}
}
