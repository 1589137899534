// @ts-nocheck
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useMemo } from 'react';
import { IllegalArgumentException } from '../../../../../../Util/Exception/IllegalArgumentException';
import { CardBorderRadius } from '../../../../../../Util/Theme';
import { calculateBestLayoutWithWidth, Layout } from './calculateBestLayoutWithWidth';
import { MixedGridItem, MixedGridItemType } from './MixedGridItem';
import { preSortForMixedGrid } from './preSortForMixedGridWithinOrderClass';

const useStyles = makeStyles(() => ({
    section: {

    }
}));

interface MixedGridProps
{
    className?: string;
    nrOfColumns: number;
    items: MixedGridItem[];
    listItemHeight: number;
    minTileHeight: number;
    style?: React.CSSProperties;
    spacing?: number;
    borderRadius?: number;
}

export const Mixedgrid = (props: MixedGridProps) => {
    const {
        className,
        nrOfColumns,
        items,
        minTileHeight,
        style,
        spacing = 8,
        borderRadius = CardBorderRadius,
    } = props;

    const sortedItems = preSortForMixedGrid(items, nrOfColumns);
    const {
        dividedItems,
    } = useItemDivision(sortedItems, nrOfColumns);

    const isListItemRow = (row: MixedGridItem[]) => row.length === 1 && row[0].type === 'LIST_ITEM';

    const classes = useStyles();

    return <div /* Wrapper for hiding the overflow caused by the negative margin of child */
        className={className}
        style={{
            flex: '1 1 auto',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignContent: 'stretch',
            overflowX: 'hidden',
            overflowY: 'hidden',

            ...style,
        }}
    >
        <div /* container for rows */
            style={{
                flex: '1 1 auto',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                flexWrap: 'nowrap',
                margin: -(spacing / 2),
            }}
        >

            {dividedItems.map(({dividedItems, columnOrRowBased, itemType}: HorizontalSection, key) => <div /* container for rows */
                key={key}
                className={classes.section}
                style={{
                    flexShrink: 0,
                    flexGrow: itemType === 'TILE' ? 1 : 0,
                    flexBasis: 'auto',

                    ...(
                        columnOrRowBased === 'row'
                            ?
                            {

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'stretch',
                                alignItems: 'stretch',
                                flexWrap: 'nowrap',
                            }
                            :
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'stretch',
                                alignItems: 'stretch',
                                flexWrap: 'nowrap',
                            }
                    )
                }}
            >
                {dividedItems.map((rowOrColumn: MixedGridItem[], i) => <div /* a row */
                    key={i}
                    style={
                        columnOrRowBased === 'row'
                            ?
                            {
                                flexShrink: 1,
                                flexGrow: isListItemRow(rowOrColumn) ? 0 : 1,
                                flexBasis: isListItemRow(rowOrColumn) ? 'auto' : `${100.0 / dividedItems.length}%`,
                                minHeight: isListItemRow(rowOrColumn) ? undefined : minTileHeight,

                                marginTop: spacing / 2,
                                marginBottom: spacing / 2,

                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'stretch',
                                alignItems: 'stretch',
                                flexWrap: 'nowrap',
                            }
                            :
                            {
                                flexShrink: 1,
                                flexGrow: isListItemRow(rowOrColumn) ? 1 : 1,
                                flexBasis: `${100.0 / dividedItems.length}%`,
                                // minHeight: isListItemRow(row) ? undefined : minTileHeight,
                                //
                                //
                                marginLeft: spacing / 2,
                                marginRight: spacing / 2,
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'stretch',
                                // alignItems: 'stretch',
                                // flexWrap: 'nowrap',
                            }
                    }
                >
                    {rowOrColumn.map((item, j) => <div
                        key={item.key !== undefined ? item.key : j}
                        style={{
                            flex: `1 ${columnOrRowBased === 'row' ? 1 : 0} ${100.0 / rowOrColumn.length}%`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'stretch',
                            alignContent: 'stretch',
                            ...(
                                columnOrRowBased === 'row'
                                    ?
                                    {
                                        marginLeft: spacing / 2,
                                        marginRight: spacing / 2,
                                    }
                                    :
                                    {
                                        marginTop: 0,
                                        marginBottom: 0,
                                        paddingTop: spacing / 2,
                                        paddingBottom: spacing / 2,
                                        minHeight: isListItemRow(rowOrColumn) ? undefined : minTileHeight,
                                    }
                            ),
                        }}
                    >
                        <div
                            style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'stretch',
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                borderRadius,
                            }}
                        >
                            <item.node
                                rowLength={rowOrColumn.length}
                            >
                            </item.node>
                        </div>
                    </div>)}
                </div>)}
            </div>)}
        </div>
    </div>;
};

interface HorizontalSection
{
    dividedItems: MixedGridItem[][]
    columnOrRowBased: 'column' | 'row'
    itemType: MixedGridItemType
}

interface ItemDivison
{
    dividedItems: HorizontalSection[]
}

function divideIntoSections(sortedItems: MixedGridItem[])
{
    const n = sortedItems.length;
    const sections: { items: MixedGridItem[], itemType: MixedGridItemType }[] = [];
    let s = 0,
        i = 0;

    for (let ii = 0; ii < n; ii++)
    {
        let item = sortedItems[ii];
        const section = ((s) => {
            if (sections[s] === undefined)
                sections[s] = {items: [], itemType: item.type};
            return sections[s].items;
        })(s);

        let nextItem = ii + 1 < n
            ?
            sortedItems[ii + 1]
            :
            undefined;
        let orderClass = item.order;
        let nextOrderClass = nextItem && nextItem.order;
        section[i] = item;
        if (item.type === 'LIST_ITEM'
            || (nextItem !== undefined && nextItem.type === 'LIST_ITEM')
            || (nextOrderClass !== undefined && nextOrderClass > orderClass))
        {
            s++;
            i = 0;
        }
        else
        {
            i++;
        }
    }
    return sections;
}

function useItemDivision(sortedItems: MixedGridItem[], nrOfColumns: number): ItemDivison
{
    const dividedItems = useMemo(
        () => {
        const sections = divideIntoSections(sortedItems);
        const result: HorizontalSection[] = [];
        for (const section of sections)
        {
            if (section.itemType === 'LIST_ITEM')
                result.push({
                    columnOrRowBased: 'row',
                    itemType: 'LIST_ITEM',
                    dividedItems: [section.items],
                });
            else
            {
                const layout = calculateBestLayoutWithWidth(nrOfColumns, section.items.length);
                const dividedItems = divideIntoGrid(layout, section.items);
                result.push({
                    columnOrRowBased: layout.groupDirection,
                    dividedItems: dividedItems,
                    itemType: section.itemType,
                });
            }
        }
        return result;
    }, [nrOfColumns, sortedItems]);
    return {
        dividedItems: dividedItems,
    };
}


function divideIntoGrid(layout: Layout, sortedItems: MixedGridItem[]): MixedGridItem[][]
{
    const grid: MixedGridItem[][] = [];

    const indices = getTileIndicesForSection(layout);

    for (let i = 0; i < sortedItems.length; i++)
    {
        const tileIndex = indices[i];
        const item = sortedItems[i];

        assertNotAListItem(item);

        const rowOrColumn = getOrDefault(grid, tileIndex.rowOrColumnIndex, []);
        rowOrColumn[tileIndex.itemInRowOrColumnIndex] = item; // the actual work
    }

    return grid;
}

function getOrDefault(grid: MixedGridItem[][], i: number, defaultValue: MixedGridItem[]) {
    if (grid[i] === undefined)
        grid[i] = defaultValue;
    return grid[i];
}

function assertNotAListItem(item: MixedGridItem)
{
    if (item.type === 'LIST_ITEM')
        throw new IllegalArgumentException('Can only divide tile items into grid');
}

type IndexOfTileInASection = {
    rowOrColumnIndex: number
    itemInRowOrColumnIndex: number
}

function getTileIndicesForSection(
    layout: Layout,
)
{
    const gridIndices: IndexOfTileInASection[] = [];

    for (let i = 0; i < layout.groupLengths.length; i++)
    {
        for (let j = 0; j < layout.groupLengths[i]; j++)
        {
            gridIndices.push({
                rowOrColumnIndex: i,
                itemInRowOrColumnIndex: j,
            });
        }
    }

    return sortIndicesSoTilesAreInTopToBottomOrder(
        gridIndices,
        layout
    );
}

function sortIndicesSoTilesAreInTopToBottomOrder(
    gridIndices: IndexOfTileInASection[],
    layout: Layout,
)
{
    return gridIndices
        .sort((a, b) => {
            const verticalDiff = relativeVerticalPositionOfCenterOfTileInASection(a, layout)
                - relativeVerticalPositionOfCenterOfTileInASection(b, layout);
            const horizontalDiff = relativeHorizontalPositionOfCenterOfTileInASection(a, layout)
                - relativeHorizontalPositionOfCenterOfTileInASection(b, layout);
            return verticalDiff !== 0
                ? verticalDiff
                : horizontalDiff;
        });
}

function relativeVerticalPositionOfCenterOfTileInASection(
    index: IndexOfTileInASection,
    layout: Layout
)
{
    if (layout.groupDirection === 'row')
    {
        return (index.rowOrColumnIndex + 0.5) / layout.groupLengths.length
    }
    else
    {
        return (index.itemInRowOrColumnIndex + 0.5) / layout.groupLengths[index.rowOrColumnIndex];
    }
}

function relativeHorizontalPositionOfCenterOfTileInASection(
    index: IndexOfTileInASection,
    layout: Layout
)
{
    if (layout.groupDirection === 'row')
    {
        return (index.itemInRowOrColumnIndex + 0.5) / layout.groupLengths[index.rowOrColumnIndex];
    }
    else
    {
        return (index.rowOrColumnIndex + 0.5) / layout.groupLengths.length;
    }
}
