import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { ProductFeatureVariant } from '../../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationStore } from '../../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupSize } from '../../option_group/FeatureConfigurationOptionGroupSize';
import { FeatureConfigurationOptionCheckbox } from './checkbox/FeatureConfigurationOptionCheckbox';
import { FeatureConfigurationOptionRadioButton } from './radio_button/FeatureConfigurationOptionRadioButton';

interface FeatureConfigurationOptionSelectorProps
{
	size: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionSelector: FC<FeatureConfigurationOptionSelectorProps> =
	({
		store,
		...other
	}) =>
	{
		const type = useObserver(() => store.featureAssignment.productFeature.type);

		switch (type)
		{
			case 'addition':
				return <FeatureConfigurationOptionCheckbox
					store={store}
					{...other}
				/>;
			case 'alternative':
				return <FeatureConfigurationOptionRadioButton
					store={store}
					{...other}
				/>;
		}
	};
