import { StorageVars } from './StorageConstants';

export const QueryParameters =
	{
		AgeVerificationToken: 'token',
		AppVersion: 'appVersion',
		BillBusinessId: 'businessId',
		BillExternalId: 'externalId',
		BillPlaceName: 'placeName',
		ComoToken: 'comoToken',
		ClearOrderOptionsAfterOrder: StorageVars.ClearOrderOptionsAfterOrder,
		ClearShoppingCartAfterReturning: StorageVars.ClearShoppingCartAfterReturning,
		ExternalShopperCardId: 'externalShopperCardId',
		ExternalShopperNotificationId: 'externalShopperNotificationId',
		HideTopBar: StorageVars.HideTopBar,
		IsKioskMode: StorageVars.IsKioskMode,
		Locale: 'locale',
		LowPerformance: StorageVars.LowPerformance,
		NavigateToBusinessRootAfterOrder: StorageVars.NavigateToBusinessRootAfterOrder,
		OAuth2Code: 'code',
		OAuth2Error: 'error',
		OAuth2RecoverMode: 'recover',
		OAuth2State: 'state',
		OrderComment: 'orderComment',
		OrderCoupon: 'orderCoupon',
		OrderDestinationAddress: 'orderDestinationAddress',
		OrderHistoryHidden: StorageVars.OrderHistoryHidden,
		ShowHistory: 'showHistory',
		SkipEntranceStories: 'skipEntranceStories',
		UserClaimToken: 't',
		UserProfileHidden: StorageVars.UserProfileHidden,
	};
