import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useBrandingService } from '../../../../branding-service/BrandingServiceProvider';
import { StoryWithPosts } from '../../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryLoadingContext } from '../../../context/StoryLoadingContext';
import { StoryPostContextProvider } from '../../../context/StoryPostContextProvider';
import { BackgroundViewer } from '../../../player/post_viewer/background/BackgroundViewer';
import { OverlayViewer } from '../../../player/post_viewer/overlay/OverlayViewer';

const AvatarSize = 70;
const ContentInset = 4.5;

interface StoryButtonProps
{
	story: StoryWithPosts;
}

export const StoryAvatar: FC<StoryButtonProps> =
	({
		story,
	}) =>
	{
		const brandingService = useBrandingService();
		const {loadPost} = useContext(StoryLoadingContext);

		const primaryColor = useObserver(() => brandingService.tintColor);

		const firstPost = useMemo(
			() =>
				story.posts[0],
			[story.posts],
		);

		useEffect(
			() =>
			{
				// noinspection JSIgnoredPromiseFromCall
				loadPost(story, 0);
			},
			[loadPost, story],
		);

		return <div
			style={{
				position: 'relative',
				height: AvatarSize,
				width: AvatarSize,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					borderRadius: '50%',
					border: `3px solid ${primaryColor.css}`,
				}}
			/>
			<div
				style={{
					position: 'absolute',
					top: ContentInset,
					right: ContentInset,
					bottom: ContentInset,
					left: ContentInset,
					borderRadius: '50%',
					overflow: 'hidden',
				}}
			>
				{
					firstPost === undefined
						? <BackgroundViewer
							backgroundColor={{
								type: 'Solid',
								color: {
									red: primaryColor.r ?? 255,
									green: primaryColor.g ?? 255,
									blue: primaryColor.b ?? 255,
									alpha: 1,
								},
							}}
						/>
						: <>
							<BackgroundViewer
								backgroundColor={firstPost.content.backgroundColor}
							/>
							<StoryPostContextProvider
								story={story}
								postIdx={0}
								active={true}
								loop
								size="Thumbnail"
							>
								<OverlayViewer
									overlay={firstPost.content.overlay}
								/>
							</StoryPostContextProvider>
						</>
				}
			</div>
		</div>;
	};
