import { BaseStore } from '@intentic/ts-foundation';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { action, computed, makeObservable, observable } from 'mobx';
import * as React from 'react';
import { OrderDestinationType, Place } from '../../../../Api/Business/Place';
import { Bridge } from '../../../../Bridge/Bridge';
import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { StorageVars } from '../../../../Constants/StorageConstants';
import { nonEmpty } from '../../../../lib/lang/nonEmpty';
import { CurrentOrderService } from '../../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../../Service/CurrentPlace/CurrentPlaceService';
import { IllegalStateException } from '../../../../Util/Exception/IllegalStateException';
import { formatValidationMessagesToText } from '../../../../Util/Validation';
import { validateEmailAddress } from '../../../UI/Form/EmailForm/validateEmailAddress';
import { phoneValidationErrors } from '../../../UI/Form/PhoneNumberInput/validatePhoneNumber';
import { EntranceStore } from '../../Entrance/EntranceStore';
import { BusinessStore } from '../BusinessStore';

export class DeliveryFormStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    currentPlaceService: CurrentPlaceService;
    currentOrderService: CurrentOrderService;
    bridge: Bridge;
    localizer: Localizer;
    entranceStore: EntranceStore;
    businessStore: BusinessStore;
    showValidation: boolean;
    buzzerNumberField: React.RefObject<any>;
    firstNameField: React.RefObject<any>;
    lastNameField: React.RefObject<any>;
    companyNameField: React.RefObject<any>;
    phoneNumberField: React.RefObject<any>;
    emailField: React.RefObject<any>;
    onClose: () => Promise<void>;
    currentFieldInFocus: 'buzzer_number' | 'first_name' | 'last_name' | 'company_name' | 'phone_number';
    phoneNumberUtil: PhoneNumberUtil;
    public addressValid: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        currentPlaceService: CurrentPlaceService,
        currentOrderService: CurrentOrderService,
        bridge: Bridge,
        entranceStore: EntranceStore,
        businessStore: BusinessStore,
        onClose: () => Promise<void>,
    )
    {
        super();

        makeObservable<DeliveryFormStore, 'place' | 'requiresBuzzerNumber' | 'requiresFirstName' | 'requiresLastName' | 'requiresCompanyName' | 'requiresPhoneNumber' | 'addressValidation' | 'currentFocussedField'>(
            this,
            {
                currentPlaceService: observable,
                currentOrderService: observable,
                bridge: observable,
                localizer: observable,
                entranceStore: observable,
                businessStore: observable,
                showValidation: observable,
                buzzerNumberField: observable,
                firstNameField: observable,
                lastNameField: observable,
                companyNameField: observable,
                phoneNumberField: observable,
                emailField: observable,
                onClose: observable,
                currentFieldInFocus: observable,
                phoneNumberUtil: observable,
                addressValid: observable,
                clearClientNameAfterOrder: computed,
                isClientNameNumeric: computed,
                summaryLabel: computed,
                summaryTypeValue: computed,
                summaryBuzzerNumber: computed,
                summaryNameValue: computed,
                summaryAddressValue: computed,
                summaryPhoneNumberValue: computed,
                summaryEmailValue: computed,
                justificationText: computed,
                place: computed,
                effectiveDestinationType: computed,
                takeoutOrEatHere: computed,
                supportsBuzzerNumber: computed,
                supportsFirstName: computed,
                supportsLastName: computed,
                supportsCompanyName: computed,
                supportsAddress: computed,
                supportsPhoneNumber: computed,
                supportsEmail: computed,
                supportsDestinationTypeChoice: computed,
                supportedDestinationTypes: computed,
                requiresBuzzerNumber: computed,
                requiresFirstName: computed,
                requiresLastName: computed,
                requiresCompanyName: computed,
                requiresPhoneNumber: computed,
                requiresEmail: computed,
                buzzerNumberLabel: computed,
                firstNameLabel: computed,
                lastNameLabel: computed,
                companyNameLabel: computed,
                phoneNumberLabel: computed,
                buzzerNumberValidation: computed,
                firstNameValidation: computed,
                lastNameValidation: computed,
                companyNameValidation: computed,
                phoneNumberValidation: computed,
                emailValidation: computed,
                destinationTypeValidation: computed,
                addressValidation: computed,
                buzzerNumberErrorMessage: computed,
                firstNameErrorMessage: computed,
                lastNameErrorMessage: computed,
                companyNameErrorMessage: computed,
                phoneNumberErrorMessage: computed,
                validation: computed,
                buzzerNumberFieldValue: computed,
                firstNameFieldValue: computed,
                lastNameFieldValue: computed,
                companyNameFieldValue: computed,
                phoneNumberFieldValue: computed,
                emailValue: computed,
                currentFocussedField: computed,
                afterOrder: action.bound,
                onBlur: action.bound,
                setBuzzerNumber: action.bound,
                setFirstName: action.bound,
                setLastName: action.bound,
                setCompanyName: action.bound,
                setPhoneNumber: action.bound,
                setEmail: action.bound,
                setShowValidation: action.bound,
                listenToEnterKey: action.bound,
                setBuzzerNumberField: action.bound,
                setFirstNameField: action.bound,
                setLastNameField: action.bound,
                setCompanyNameField: action.bound,
                focus: action.bound,
                blur: action.bound,
                setAddressValid: action.bound,
            },
        );

        this.currentPlaceService = currentPlaceService;
        this.currentOrderService = currentOrderService;
        this.bridge = bridge;
        this.localizer = bridge.localizer;
        this.entranceStore = entranceStore;
        this.businessStore = businessStore;
        this.showValidation = false;
        this.buzzerNumberField = React.createRef();
        this.firstNameField = React.createRef();
        this.lastNameField = React.createRef();
        this.companyNameField = React.createRef();
        this.phoneNumberField = React.createRef();
        this.onClose = onClose;
        this.phoneNumberUtil = new PhoneNumberUtil();
        this.addressValid = false;
    }

    // ----------------------- Initialization -----------------------

    async initialize(): Promise<void>
    {
        if (this.clearClientNameAfterOrder || this.isClientNameNumeric) {
            this.setFirstName('');
        } else {
            const name = this.bridge.storage.get(StorageVars.ClientName);
            this.setFirstName(name || '');
        }
    }

    // -------------------------- Computed --------------------------

    get clearClientNameAfterOrder(): boolean
    {
        return this.entranceStore.clearOrderOptionsAfterOrder.value;
    }

    get isClientNameNumeric(): boolean
    {
        return this.businessStore.place.isClientNameNumeric;
    }

    get summaryLabel(): string
    {
        if (this.effectiveDestinationType === undefined)
        {
            return this.localizer.translate('Client-Order-Type')
        }
        else if (this.supportsFirstName && !this.supportsBuzzerNumber && !this.supportsCompanyName && !this.supportsPhoneNumber && !this.supportsEmail && this.supportedDestinationTypes.length <= 1)
        {
            return this.isClientNameNumeric
                ?
                this.localizer.translate('Client-Table-Sign-Number')
                :
                (
                    this.supportsLastName
                        ?
                        this.localizer.translate('Client-Name')
                        :
                        this.localizer.translate('Client-FirstName')
                )
        }
        else if (this.effectiveDestinationType === 'ADDRESS')
        {
            return this.localizer.translate('Client-Order-Delivery');
        }
        else if (this.effectiveDestinationType === 'PLACE')
        {
            if (this.supportedDestinationTypes.length > 1)
            {
                return this.localizer.translate('Client-Order-Eat-Here');
            }
            else
            {
                const labelNames = [
                    ...this.supportsFirstName ? [this.firstNameLabel] : [],
                    ...this.supportsLastName ? [this.lastNameLabel] : [],
                    ...this.supportsCompanyName ? [this.companyNameLabel] : [],
                    ...this.supportsAddress ? [this.localizer.translate('Client-Order-Service')] : [],
                    ...this.supportsPhoneNumber ? [this.phoneNumberLabel] : [],
                    ...this.supportsEmail ? [this.emailLabel] : [],
                ];

                if (labelNames.length === 1)
                {
                    return labelNames[0];
                }
                else
                {
                    // Because in this case, the settings in the DeliveryForm _relatively_ pertain to delivery, as the entire
                    // table is scoped on eating here
                    return this.localizer.translate('Client-Order-Service');
                }
            }
        }
        else if (this.effectiveDestinationType === 'PICKUP_POINT')
        {
            return this.localizer.translate('Client-Order-Pick-Up');
        }
        else
        {
            throw new IllegalStateException();
        }
    }

    get summaryTypeValue(): string
    {
        return this.supportedDestinationTypes.length > 1
            ?
            (() => {
                const type = this.currentOrderService.effectiveDestinationType;
                switch (type)
                {
                    case 'ADDRESS':
                        return this.summaryAddressValue !== undefined
                            ?
                            this.localizer.translate('Client-Order-Deliver-To')
                            :
                            this.localizer.translate('Client-Order-Delivery');
                    case 'PICKUP_POINT':
                        return this.localizer.translate('Client-Order-Pick-Up');
                    case 'PLACE':
                        return this.localizer.translate('Client-Order-Eat-Here');
                    default:
                        return '';
                }
            })()
            :
            '';
    }

    get summaryBuzzerNumber(): string | undefined
    {
        return this.supportsBuzzerNumber
            ?
            this.currentOrderService.buzzerNumber
            :
            '';
    }

    get summaryNameValue(): string
    {
        const effectivePersonName = (() => {
            if (this.effectiveDestinationType === 'ADDRESS')
                return this.currentOrderService.destinationAddress.value?.name ?? '';
            else
            {
                const firstName = this.supportsFirstName
                    ? this.currentOrderService.clientFirstName.value
                    : undefined;
                const lastName = this.supportsLastName
                    ? this.currentOrderService.clientLastName.value
                    : undefined;

                return (firstName ?? '')
                    + (nonEmpty(firstName, lastName) ? ' ' : '')
                    + (lastName ?? '');
            }
        })();

        const effectiveCompanyName = this.supportsCompanyName
            ?
            this.currentOrderService.clientCompanyName.value
            :
            (
                this.effectiveDestinationType === 'ADDRESS'
                    ? this.currentOrderService.destinationAddress.value?.companyName
                    : undefined
            );

        const combinedName = effectivePersonName
            + (nonEmpty(effectivePersonName, effectiveCompanyName) ? ' (' : '')
            + (nonEmpty(effectiveCompanyName) ? effectiveCompanyName : '')
            + (nonEmpty(effectivePersonName, effectiveCompanyName) ? ')' : '');
        return combinedName ?? '';
    }

    get summaryAddressValue(): string | undefined
    {
        return this.supportsAddress
            ?
            this.currentOrderService.destinationAddress.value?.singleLineValue
            :
            '';
    }

    get summaryPhoneNumberValue(): string | undefined
    {
        return this.supportsPhoneNumber
            ?
            this.currentOrderService.clientPhoneNumber.value
            :
            '';
    }

    get summaryEmailValue(): string | undefined
    {
        return this.supportsEmail
            ?
            this.currentOrderService.clientEmail.value
            :
            '';
    }

    get justificationText(): string
    {
        if (this.isClientNameNumeric)
        {
            return this.localizer.translate('Client-Table-Sign-Number-Justification');
        }
        else if (this.supportsBuzzerNumber || this.supportsCompanyName || this.supportsPhoneNumber || this.supportsEmail || this.supportsAddress)
        {
            return this.currentOrderService.destinationType.value === 'PICKUP_POINT'
                ? this.localizer.translate('Client-Details-Justification-PickUp')
                : this.localizer.translate('Client-Details-Justification');
        }
        else
        {
            return this.currentOrderService.destinationType.value === 'PICKUP_POINT'
                ? this.localizer.translate('Client-Name-Justification-PickUp')
                : this.localizer.translate('Client-Name-Justification');
        }
    }

    private get place(): Place | undefined
    {
        return this.currentPlaceService.place;
    }

    public get effectiveDestinationType()
    {
        return this.currentOrderService.effectiveDestinationType;
    }

    public get takeoutOrEatHere()
    {
        return this.currentOrderService.takeoutOrEatHere;
    }

    public get supportsBuzzerNumber(): boolean
    {
        return this.currentOrderService.showBuzzer;
    }

    public get supportsFirstName(): boolean
    {
        return this.currentOrderService.showFirstName;
    }

    public get supportsLastName(): boolean
    {
        return this.currentOrderService.showLastName;
    }

    public get supportsCompanyName(): boolean
    {
        return this.currentOrderService.showCompanyName;
    }

    public get supportsAddress(): boolean
    {
        return this.currentOrderService.showAddress;
    }

    public get supportsPhoneNumber(): boolean
    {
        return this.currentOrderService.showPhoneNumberInDeliveryForm;
    }

    public get supportsEmail(): boolean
    {
        return this.currentOrderService.showEmail;
    }

    public get supportsDestinationTypeChoice(): boolean
    {
        return this.supportedDestinationTypes.length > 1;
    }

    public get supportedDestinationTypes(): OrderDestinationType[]
    {
        return this.place?.supportedDestinationTypes ?? [];
    }

    private get requiresBuzzerNumber(): boolean
    {
        return this.supportsBuzzerNumber; // same as supportsBuzzerNumber, because supporting means it is required
    }

    private get requiresFirstName(): boolean
    {
        return this.supportsFirstName; // same as supportsFirstName because the first name cannot be optional as it is a simple nonnullable boolean in the db (should be fixed one day)
    }

    private get requiresLastName(): boolean
    {
        return this.place?.lastNameField === 'REQUIRED';
    }

    private get requiresCompanyName(): boolean
    {
        return this.place?.companyNameField === 'REQUIRED';
    }

    private get requiresPhoneNumber(): boolean
    {
        return this.place?.phoneNumberField === 'REQUIRED';
    }

    get requiresEmail(): boolean
    {
        return this.place?.emailField === 'REQUIRED';
    }

    get buzzerNumberLabel(): string
    {
        return this.localizer.translate('Generic-Buzzer-Number');
    }

    get firstNameLabel(): string
    {
        return this.isClientNameNumeric
            ?
            this.localizer.translate('Client-Table-Sign-Number')
            :
            (
                this.supportsLastName
                    ?
                    this.localizer.translate('Client-FirstName')
                    :
                    this.localizer.translate('Client-Name')
            );
    }

    get lastNameLabel(): string
    {
        return this.localizer.translate('Client-LastName');
    }

    get companyNameLabel(): string
    {
        return this.localizer.translate('Client-CompanyName');
    }

    get phoneNumberLabel(): string
    {
        return this.localizer.translate('Client-PhoneNumber');
    }

    get emailLabel(): string
    {
        return this.localizer.translate('Generic-Email-Address');
    }

    get buzzerNumberValidation(): string[]
    {
        if (!this.supportsBuzzerNumber)
        {
            return [];
        }
        else
        {
            const buzzer = this.currentOrderService.buzzerNumber;

            const buzzerEmpty = buzzer === undefined || buzzer.trim().length === 0;

            if (this.requiresBuzzerNumber && buzzerEmpty)
            {
                return [
                    this.localizer.translate('Generic-Buzzer-Number-Required-Label')
                ];
            }
            else
            {
                return [];
            }
        }
    }

    get firstNameValidation(): string[]
    {
        const errors: string[] = [];

        const firstName = this.currentOrderService.clientFirstName.value;

        if (firstName === null)
        {
            throw new IllegalStateException('First name is null');
        }

        const firstNameEmpty = firstName === undefined || firstName.trim().length === 0;
        if (this.requiresFirstName && firstNameEmpty)
        {
            errors.push(this.localizer.translate(
                this.isClientNameNumeric
                    ?
                    'Client-Table-Sign-Number-Required'
                    :
                    'Client-Name-Required',
            ));
        }


        if (!firstNameEmpty && this.isClientNameNumeric && isNaN(parseInt(firstName, 10)))
        {
            errors.push(this.localizer.translate('Client-Number-Not-Numeric'));
        }

        return errors;
    }

    get lastNameValidation(): string[]
    {
        let errors: string[] = [];

        if (!this.supportsLastName)
        {
            return [];
        }

        const lastName = this.currentOrderService.clientLastName.value;

        if (this.requiresLastName && (lastName === undefined || lastName.trim().length === 0))
        {
            errors.push(this.localizer.translate('Client-LastName-Required'));
        }

        if (lastName !== undefined)
        {
            const preProcessedName = lastName.trim();

            if (preProcessedName.length > 50)
            {
                errors.push(this.localizer.translate('Validation-StringMaxLength'));
            }

            const illegalCharError = this.errorMessageForIllegalCharacters(preProcessedName);
            if (illegalCharError !== undefined)
            {
                errors.push(illegalCharError);
            }
        }

        return errors;
    }

    get companyNameValidation(): string[]
    {
        let errors: string[] = [];

        if (!this.supportsCompanyName)
        {
            return [];
        }

        const companyName = this.currentOrderService.clientCompanyName.value;

        if (this.requiresCompanyName && (companyName === undefined || companyName.trim().length === 0))
        {
            errors.push(this.localizer.translate('Client-CompanyName-Required'));
        }

        if (companyName !== undefined)
        {
            const preProcessedName = companyName.trim();

            if (preProcessedName.length > 50)
            {
                errors.push(this.localizer.translate('Validation-StringMaxLength'));
            }

            const illegalCharError = this.errorMessageForIllegalCharacters(preProcessedName);
            if (illegalCharError !== undefined)
            {
                errors.push(illegalCharError);
            }
        }

        return errors;
    }

    get phoneNumberValidation(): string[]
    {
        if (!this.supportsPhoneNumber)
        {
            return [];
        }

        return phoneValidationErrors(
            this.requiresPhoneNumber,
            this.currentOrderService.clientPhoneNumber.value,
            this.localizer,
            this.phoneNumberUtil
        );
    }

    get emailValidation(): string[]
    {
        if (!this.supportsEmail)
        {
            return [];
        }
        return validateEmailAddress(
            this.requiresEmail,
            this.currentOrderService.clientEmail.value,
            this.localizer
        );
    }

    get destinationTypeValidation(): string[]
    {
        return this.currentOrderService.effectiveDestinationType !== undefined
            ?
            []
            :
            [this.localizer.translate('Client-Order-Type-Required')]
    }

    private get addressValidation(): string[]
    {
        if (!this.supportsAddress)
        {
            return [];
        }

        return this.addressValid
            ?
            []
            :
            [this.localizer.translate('Address-No-Valid-Address')]
    }

    private errorMessageForIllegalCharacters(checkedString: string): string
    {
        const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
        const matches = checkedString.match(format) != null ? checkedString.match(format) : [];
        if (matches.length > 0)
        {
            const illegalCharacters = [];
            for (let match of matches)
            {
                illegalCharacters.push(`'${match}'`);
            }
            let illegalCharactersSummation = '';
            for (let i = 0; i < illegalCharacters.length; i++)
            {
                const seperator = i > 0
                    ?
                    (
                        i < illegalCharacters.length - 1
                            ?
                            ', '
                            :
                            ' or '
                    )
                    :
                    '';
                illegalCharactersSummation += `${seperator}${illegalCharacters[i]}`;
            }
            return this.localizer.translate('Validation-StringMayNotContain', illegalCharactersSummation);
        }
        return undefined;
    }

    get buzzerNumberErrorMessage(): string | undefined
    {
        return this.buzzerNumberValidation.length > 0
            ?
            formatValidationMessagesToText(this.buzzerNumberValidation)
            :
            undefined;
    }

    get firstNameErrorMessage(): string | undefined
    {
        return this.firstNameValidation.length > 0
            ?
            formatValidationMessagesToText(this.firstNameValidation)
            :
            undefined;
    }

    get lastNameErrorMessage(): string | undefined
    {
        return this.lastNameValidation.length > 0
            ?
            formatValidationMessagesToText(this.lastNameValidation)
            :
            undefined;
    }

    get companyNameErrorMessage(): string | undefined
    {
        return this.companyNameValidation.length > 0
            ?
            formatValidationMessagesToText(this.companyNameValidation)
            :
            undefined;
    }

    get phoneNumberErrorMessage(): string | undefined
    {
        return this.phoneNumberValidation.length > 0
            ?
            formatValidationMessagesToText(this.phoneNumberValidation)
            :
            undefined;
    }

    get validation()
    {
        return [
            ...this.buzzerNumberValidation,
            ...this.firstNameValidation,
            ...this.lastNameValidation,
            ...this.companyNameValidation,
            ...this.phoneNumberValidation,
            ...this.emailValidation,
            ...this.destinationTypeValidation,
            ...this.addressValidation,
        ];
    }

    get buzzerNumberFieldValue(): string
    {
        return this.currentOrderService.buzzerNumber !== undefined
            ?
            this.currentOrderService.buzzerNumber
            :
            '';
    }

    get firstNameFieldValue(): string
    {
        return this.currentOrderService.clientFirstName.value !== undefined
            ?
            this.currentOrderService.clientFirstName.value
            :
            '';
    }

    get lastNameFieldValue(): string
    {
        return this.currentOrderService.clientLastName.value !== undefined
            ?
            this.currentOrderService.clientLastName.value
            :
            '';
    }

    get companyNameFieldValue(): string
    {
        return this.currentOrderService.clientCompanyName.value !== undefined
            ?
            this.currentOrderService.clientCompanyName.value
            :
            '';
    }

    get phoneNumberFieldValue(): string
    {
        return this.currentOrderService.clientPhoneNumber.value;
    }

    get emailValue(): string
    {
        return this.currentOrderService.clientEmail.value !== undefined
            ?
            this.currentOrderService.clientEmail.value
            :
            '';
    }

    exitsUI(isUnmounted: boolean)
    {
        if (isUnmounted)
        {
            this.blur();
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    public afterOrder(): void
    {
        if (this.clearClientNameAfterOrder) {
            this.setFirstName('');
        }

        this.setBuzzerNumber('');
    }

    // ------------------------ Public logic ------------------------

    public onBlur(goalElement: HTMLElement)
    {
        if (goalElement !== this.buzzerNumberField.current &&
            goalElement !== this.firstNameField.current &&
            goalElement !== this.lastNameField.current &&
            goalElement !== this.companyNameField.current &&
            goalElement !== this.phoneNumberField.current)
        {
            this.setShowValidation(true);
        }
    }

    setBuzzerNumber(buzzer: string)
    {
        this.currentOrderService.setBuzzerNumber(
            buzzer !== ''
                ?
                buzzer
                :
                undefined
        );
    }

    setFirstName(clientName: string)
    {
        this.currentOrderService.clientFirstName.set(
            clientName !== ''
                ?
                clientName
                :
                undefined
        );
    }

    setLastName(lastName: string)
    {
        this.currentOrderService.clientLastName.set(
            lastName !== ''
                ?
                lastName
                :
                undefined
        );
    }

    setCompanyName(companyName: string)
    {
        this.currentOrderService.clientCompanyName.set(
            companyName !== ''
                ?
                companyName
                :
                undefined
        );
    }

    setPhoneNumber(phoneNumber: string)
    {
        this.currentOrderService.clientPhoneNumber.set(
            phoneNumber !== ''
                ?
                phoneNumber
                :
                undefined
        );
    }

    setEmail(email: string)
    {
        this.currentOrderService.clientEmail.set(
            email !== ''
                ?
                email
                :
                undefined
        );
    }

    setShowValidation(showValidation: boolean)
    {
        this.showValidation = showValidation;
    }

    listenToEnterKey(ev: React.KeyboardEvent)
    {
        if (ev.key === 'Enter')
        {
            return this.onClose();
        }
    }

    private get currentFocussedField(): React.RefObject<any> | undefined
    {
        switch (this.currentFieldInFocus)
        {
            case 'buzzer_number':
                return this.buzzerNumberField;
            case 'company_name':
                return this.companyNameField;
            case 'first_name':
                return this.firstNameField;
            case 'last_name':
                return this.lastNameField;
            case 'phone_number':
                return this.phoneNumberField;
            default:
                return undefined;
        }
    }

    setBuzzerNumberField(textField: React.RefObject<any>)
    {
        this.buzzerNumberField = textField;
    }

    setFirstNameField(textField: React.RefObject<any>)
    {
        this.firstNameField = textField;
    }

    setLastNameField(textField: React.RefObject<any>)
    {
        this.lastNameField = textField;
    }

    setCompanyNameField(textField: React.RefObject<any>)
    {
        this.companyNameField = textField;
    }

    focus()
    {
        if (this.showValidation && this.buzzerNumberValidation.length > 0
            && this.buzzerNumberField?.current)
        {
            this.buzzerNumberField.current.focus();
        }
        else if (this.showValidation && this.firstNameValidation.length > 0
            && this.firstNameField?.current)
        {
            this.firstNameField.current.focus();
        }
        else if (this.showValidation && this.lastNameValidation.length > 0
            && this.lastNameField?.current)
        {
            this.lastNameField.current.focus();
        }
        else if (this.showValidation && this.companyNameValidation.length > 0
            && this.companyNameField?.current)
        {
            this.companyNameField.current.focus();
        }
        else if (this.showValidation && this.phoneNumberValidation.length > 0
            && this.phoneNumberField?.current)
        {
            this.phoneNumberField.current.focus();
        }
        else if (this.buzzerNumberField?.current)
        {
            this.buzzerNumberField.current.focus();
        }
        else if (this.firstNameField?.current)
        {
            this.firstNameField.current.focus();
        }
    }

    blur()
    {
        if (this.currentFocussedField && this.currentFocussedField.current)
        {
            this.currentFocussedField.current.blur();
        }
    }

    public setAddressValid(addressValid: boolean)
    {
        this.addressValid = addressValid;
    }

    // ----------------------- Private logic ------------------------
}
