import { PlaceSession, PlaceSessionProfile } from '../../../../../Api/Business/PlaceSession';
import { fetchList } from '../../../../../Util/Api';

export function getPlaceSessions(params: GetPlaceSessionsParams): Promise<PlaceSession[]>
{
	return fetchList('/waiter/business/place/sessions/open', params, PlaceSessionProfile);
}

interface GetPlaceSessionsParams
{
	business_id: number;
}