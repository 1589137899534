import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { PaymentMethodId } from '../Payment/PaymentMethodId';

export type OrderProcessingState = 'scheduled' | 'processing' | 'processed' | 'failed' | 'partially_failed';

export type OrderState = 'scheduled' | 'ordered' | 'acknowledged' | 'handled' | 'voided' | 'prepared' | 'pickedUp' | 'delivered';

export type PaymentState = 'created' | 'negotiated' | 'failed' | 'unpaid' | 'paid';

export type PickupPointReferenceType = 'PickupCode' | 'Buzzer';

export class Order
{
    // ------------------------- Properties -------------------------

    id: number;
    uuid: string;
    comment: string | undefined;
    state: OrderState;
    paymentGateway?: string;
    paymentMethod: PaymentMethodId;
    paymentMethodId?: string;
    paymentState: PaymentState;
    paymentCurrencyCode: string;
    paymentPrice: Decimal | undefined;
    paymentUrl: string;
    newCartId: number;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get hasPayment(): boolean
    {
        return this.paymentState !== undefined && this.paymentMethod !== undefined && this.paymentMethod !== 'traditional';
    }

    get hasOpenPayment(): boolean
    {
        return this.hasPayment
            && this.state !== 'voided'
            && this.paymentMethod !== 'traditional'
            && this.paymentState === 'negotiated'
            && this.paymentCurrencyCode != null
            && this.paymentPrice != null;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    /*---------------------------------------------------------------*
     *                      Getters and setters                      *
     *---------------------------------------------------------------*/
}

export const OrderProfile =
    SerializationProfile.create(Order)
        .transform('paymentPrice', NumberToDecimalTransformer);
