import { Box, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { ProductFeatureVariant } from '../../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationOptionGroupSize } from '../../option_group/FeatureConfigurationOptionGroupSize';
import { FeatureConfigurationOptionDescription } from './description/FeatureConfigurationOptionDescription';
import { FeatureConfigurationOptionName } from './name/FeatureConfigurationOptionName';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		gap: 5,
	},
});

interface FeatureConfigurationOptionCaptionProps
{
	size: FeatureConfigurationOptionGroupSize;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionCaption: FC<FeatureConfigurationOptionCaptionProps> =
	props =>
	{
		const classes = useStyles();

		return <Box
			className={classes.container}
		>
			<FeatureConfigurationOptionName
				{...props}
			/>
			<FeatureConfigurationOptionDescription
				{...props}
			/>
		</Box>;
	};
