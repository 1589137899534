import { makeAutoObservable } from 'mobx';
import { ISO8601ToDateTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';

export class CartDelete
{
	// ------------------------- Properties -------------------------

	timestamp: Date;
	cartId: number;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}

export const CartDeleteProfile = SerializationProfile.create(CartDelete)
		.transform('timestamp', ISO8601ToDateTransformer);
