import { FC, PropsWithChildren, useContext, useMemo } from 'react';
import { StoryWithPosts } from '../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryPostSize } from '../util/StoryPostSize';
import { StoryLoadingContext } from './StoryLoadingContext';
import { StoryPostContext } from './StoryPostContext';

interface StoryPostContextProviderProps
{
	story: StoryWithPosts;
	postIdx: number;
	active: boolean;
	loop?: boolean;
	onContinue?: () => Promise<void>;
	size: StoryPostSize;
}

export const StoryPostContextProvider: FC<PropsWithChildren<StoryPostContextProviderProps>> =
	({
		children,
		story,
		postIdx,
		active,
		loop,
		onContinue,
		size,
	}) =>
	{
		const {didLoadPost} = useContext(StoryLoadingContext);

		const didLoad = useMemo(
			() =>
				didLoadPost(story, postIdx),
			[didLoadPost, postIdx, story],
		);

		const contextValue = useMemo(() => ({
			post: story.posts[postIdx],
			active,
			loop,
			onContinue,
			size,
			didLoad,
		}), [active, didLoad, loop, onContinue, postIdx, size, story.posts]);

		return <StoryPostContext.Provider
			value={contextValue}
		>
			{children}
		</StoryPostContext.Provider>;
	};
