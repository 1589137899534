import { useObserver } from 'mobx-react-lite';
import { CSSProperties, FC, memo, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { VideoOverlay } from '../../../../../../Api/v3/model/story_post/content/overlay/VideoOverlay';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

interface VideoOverlayViewerProps
{
	overlay: VideoOverlay;
	paused?: boolean;
}

export const VideoOverlayViewer: FC<VideoOverlayViewerProps> = memo(
	({
		overlay,
		paused,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {onChangePlayedPercentage} = useContext(StoryPlayerContext);
		const {active, didLoad, loop, onContinue, size} = useContext(StoryPostContext);

		const [sourceDidLoad, setSourceDidLoad] = useState(false);

		const getLoadedStoryPostFile = useObserver(() => businessStore.storyPostFileService.getLoadedStoryPostFile);

		const videoRef = useRef<HTMLVideoElement>(null);

		const storyPostFile = useMemo(
			() =>
			{
				if (didLoad)
				{
					switch (size)
					{
						case 'Player':
							return getLoadedStoryPostFile(overlay.fileId);
						case 'Thumbnail':
							return getLoadedStoryPostFile(overlay.thumbnailFileId ?? overlay.fileId);
					}
				}
			},
			[didLoad, getLoadedStoryPostFile, overlay.fileId, overlay.thumbnailFileId, size],
		);

		const posterFile = useMemo(
			() =>
				didLoad && overlay.posterFileId !== undefined
					? getLoadedStoryPostFile(overlay.posterFileId)
					: undefined,
			[didLoad, getLoadedStoryPostFile, overlay.posterFileId],
		);

		const pauseAndReset = useCallback(
			(video: HTMLVideoElement) =>
			{
				video.pause();

				if (typeof video.fastSeek === 'function')
					video.fastSeek(0);
				else
					video.currentTime = 0;
			},
			[],
		);

		useEffect(
			() =>
			{
				const video = videoRef.current;

				if (video !== null)
				{
					if (active)
					{
						const isCurrentlyPlaying = isPlaying(video);

						if (paused && isCurrentlyPlaying)
							video.pause();
						else if (didLoad && sourceDidLoad && !isCurrentlyPlaying)
							// noinspection JSIgnoredPromiseFromCall
							video.play();
					}
					else if (isPlaying(video))
					{
						pauseAndReset(video);
					}
				}
			},
			[active, didLoad, pauseAndReset, paused, sourceDidLoad],
		);

		const handleEnded = useCallback(
			(event: SyntheticEvent<HTMLVideoElement>) =>
			{
				if (active)
				{
					return loop
						? event.currentTarget.play()
						: onContinue?.();
				}
			},
			[active, loop, onContinue],
		);

		const handleLoadedData = useCallback(
			async (event: SyntheticEvent<HTMLVideoElement>) =>
			{
				setSourceDidLoad(true);

				if (!active)
					pauseAndReset(event.currentTarget);
			},
			[active, pauseAndReset],
		);

		const handleTimeUpdate = useCallback(
			(event: SyntheticEvent<HTMLVideoElement>) =>
				onChangePlayedPercentage(
					1000 * event.currentTarget.currentTime / overlay.durationInMilliseconds,
				),
			[onChangePlayedPercentage, overlay.durationInMilliseconds],
		);

		return <>
			<video
				ref={videoRef}
				poster={posterFile?.fileUrl}
				src={storyPostFile?.fileUrl}
				autoPlay
				muted
				playsInline
				style={{
					display: active || posterFile === undefined
						? undefined
						: 'none',
					position: 'absolute',
					top: 0,
					left: 0,

					width: '100%',
					height: '100%',
					objectFit: overlay.fit.toLowerCase() as CSSProperties['objectFit'],
					objectPosition: `${overlay.position.x * 100}% ${overlay.position.y * 100}%`,
					pointerEvents: 'none',
				}}
				onEnded={handleEnded}
				onLoadedData={handleLoadedData}
				onTimeUpdate={
					active
						? handleTimeUpdate
						: undefined
				}
			/>
			{
				!active && posterFile !== undefined &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,

						backgroundImage: `url(${posterFile.fileUrl})`,
						backgroundPosition: `${overlay.position.x * 100}% ${overlay.position.y * 100}%`,
						backgroundSize: overlay.fit.toLowerCase(),
					}}
				/>
			}
		</>;
	},
);

function isPlaying(video: HTMLVideoElement): boolean
{
	return video.currentTime > 0 &&
		!video.paused &&
		!video.ended &&
		video.readyState > video.HAVE_CURRENT_DATA;
}