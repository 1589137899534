import { Avatar, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Decimal from 'decimal.js';
import * as React from 'react';
import { CSSProperties, FC, MouseEvent, useCallback, useMemo } from 'react';
import { ProductOrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';

const AvatarSize = 32;

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            avatar:
                {
                    alignSelf: 'flex-start',
                    height: AvatarSize,
                    width: AvatarSize,
                },
            descriptionContainer:
                {
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: AvatarSize,
                },
            descriptionText:
                {
                    marginLeft: theme.spacing(1),
                },
            descriptionTextAligned:
                {
                    marginLeft: AvatarSize + theme.spacing(1),
                },
            priceContainer:
                {
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    display: 'flex',
                    height: AvatarSize,
                    marginLeft: theme.spacing(1),
                },
            priceText:
                {
                },
            root:
                {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(1),
                },
        }));

export interface OrderLineCollapsedProps
{
    alignWithAvatars?: boolean;
    onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
    orderLine: ProductOrderLineDescriptor;
    showPriceAsCurrency?: string;
    style?: CSSProperties;
}

export const ProductOrderLineCollapsed: FC<OrderLineCollapsedProps> =
    (
        {
            alignWithAvatars,
            onClick,
            orderLine,
            showPriceAsCurrency,
            style,
        },
    ) =>
    {
        const classes = useStyles();

        const localizer = useLocalizer();

        const getCollapsedVariantsText = useCallback(() => {
            if (orderLine.variants.length > 0)
            {
                let variantsText = '';

                orderLine.variants.forEach(
                    (variant, idx) =>
                    {
                        if (idx === 0)
                        {
                            variantsText = '(' + variant.description;
                        }
                        else
                        {
                            variantsText = variantsText + ', ' + variant.description;
                        }
                    });

                variantsText = variantsText + ')';

                return variantsText;
            }
            else
            {
                return null;
            }
        }, [orderLine.variants]);

        const descriptionTextClassString = useMemo(
            () => {
                if (orderLine.imageUrl === undefined && alignWithAvatars)
                {
                    return classes.descriptionTextAligned;
                }
                else
                {
                    return classes.descriptionText;
                }
            },
            [alignWithAvatars, classes.descriptionText, classes.descriptionTextAligned, orderLine.imageUrl]
        );

        const onUserClick = useCallback(
            (event: MouseEvent<HTMLDivElement>) => {
                if (orderLine.variants.length > 0 && onClick !== undefined)
                {
                    onClick(event);
                    event.stopPropagation();
                }
            },
            [onClick, orderLine.variants]
        );

        return <div
            className={classes.root}
            onClick={onUserClick}
            style={style}
        >
            <div
                className={classes.descriptionContainer}
            >
                {
                    orderLine.imageUrl !== undefined &&
                    <Avatar
                        classes={{
                            root: classes.avatar,
                        }}
                        src={getFileUrl(`/image/${AvatarSize}/${AvatarSize}/Cover/High${orderLine.imageUrl}`)}
                    />
                }
                <Typography
                    classes={{
                        root: descriptionTextClassString,
                    }}
                >
                    {orderLine.quantity}x&nbsp;{orderLine.description} {getCollapsedVariantsText()}
                </Typography>
            </div>
            {
                showPriceAsCurrency &&
                orderLine.quantity > 0 &&
                orderLine.price > 0 &&
                <div className={classes.priceContainer}>
                    <Typography
                        classes={{
                            root: classes.priceText,
                        }}
                    >
                        {localizer.formatCurrency(new Decimal(orderLine.quantity * orderLine.price), showPriceAsCurrency)}
                    </Typography>
                </div>
            }
        </div>;
    };
