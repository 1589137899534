import { Grid, Theme } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Place } from '../../../../Api/Business/Place';
import { PlaceSession } from '../../../../Api/Business/PlaceSession';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { useManagerPlaceSessionsContext } from '../context/place-session/ManagerPlaceSessionsContext';
import { useSortedManagerPlaces } from '../context/place/ManagerPlacesContext';
import { ManagerPlacesToolbar } from './ManagerPlacesToolbar';
import { PlaceButton } from './PlaceButton/PlaceButton';
import { SessionEditorDialog } from './SessionEditor/Dialog/SessionEditorDialog';

const useStyles = makeStyles((theme: Theme) => ({
	placeContainer: {
		margin: 0,
		overflowY: 'auto',
		padding: theme.spacing(1),
		WebkitOverflowScrolling: 'touch',
		width: '100%',
	},
	placeItem: {
		padding: '4px !important',
	},
}));

export const ManagerPlaces: FC =
	() =>
	{
		const classes = useStyles();

		const places = useSortedManagerPlaces();
		const {savePlaceSession} = useManagerPlaceSessionsContext();

		const [editorPlaceSession, setEditorPlaceSession] = useState<PlaceSession | undefined>(undefined);
		const [infoPlaceSession, setInfoPlaceSession] = useState<PlaceSession | undefined>(undefined);
		const [searchTerm, setSearchTerm] = useState('');
		const [showDetails, setShowDetails] = useState(false);

		const onSavePlaceSession = useCallback(async (placeSession: PlaceSession, endSession: boolean) => {
			const updatedPlaceSession = await savePlaceSession(placeSession, endSession ?? false);

			if (infoPlaceSession !== undefined && updatedPlaceSession !== undefined)
				setInfoPlaceSession(Object.create(placeSession));

			return Promise.resolve(true);
		}, [infoPlaceSession, savePlaceSession]);

		const onPlaceSelect = useCallback(
			(place: Place, session: PlaceSession | undefined) => setEditorPlaceSession(session ?? new PlaceSession(place, 2, [])),
			[],
		);

		const onSessionEditorDialogClose = useCallback(
			() => setEditorPlaceSession(undefined),
			[],
		);

		const onPlaceSettingsSelect = useCallback(() => {
			window.open(`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/configuration/places`, '_blank')
		}, []);

		const {
			waiterService,
		} = useContext(EntranceContextRef);

		const allowedToEdit = useMemo(() => waiterService.user?.type === 'Owner', [waiterService.user]);

		const placeButtons = useMemo(() => {
			return places?.map(place => {
				return <Fade
					in={place.name.includes(searchTerm)}
					key={place.id}
				>
					<Grid
						classes={{
							item: classes.placeItem,
						}}
						item
						style={{
							display: place.name.includes(searchTerm) ? 'flex' : 'none',
						}}
					>
						<PlaceButton
							isShown={editorPlaceSession && editorPlaceSession.place.id === place.id}
							onClick={onPlaceSelect}
							onClickSettings={onPlaceSettingsSelect}
							place={place}
							showDetails={showDetails}
							allowedToEdit={allowedToEdit}
						/>
					</Grid>
				</Fade>;
			});
		}, [allowedToEdit, classes.placeItem, editorPlaceSession, onPlaceSelect, onPlaceSettingsSelect, places, searchTerm, showDetails]);

		return <>
			<SessionEditorDialog
				onClose={onSessionEditorDialogClose}
				onSavePlaceSession={onSavePlaceSession}
				placeSession={editorPlaceSession}
			/>
			<ManagerPlacesToolbar
				onSearch={setSearchTerm}
				onShowDetails={setShowDetails}
			/>
			<Grid
				alignItems="flex-start"
				classes={{
					container: classes.placeContainer,
				}}
				container
				justifyContent="center"
				spacing={2}
			>
				{placeButtons}
			</Grid>
		</>;
	};
