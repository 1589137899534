import { makeAutoObservable } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { LanguageEntryTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { LanguageEntryTranslations } from '../Other/LanguageEntryTranslations';
import { ProductProductRecommendationList } from './ProductProductRecommendationList';
import { normalizeProductRecommendations, ProductRecommendation, ProductRecommendationProfile } from './ProductRecommendation';
import { ProductRecommendationMoment } from './recommendation/ProductRecommendationMoment';

export class ProductRecommendationList
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly uuid: string;

	/**
	 * @deprecated replaced by {@link ProductProductRecommendationList#triggeringProductId}
	 */
	triggeringProductId: number;
	recommendationText?: LanguageEntryTranslations | string;
	name?: string;
	moment: ProductRecommendationMoment;
	productRecommendations: ProductRecommendation[];
	productProductRecommendationLists?: ProductProductRecommendationList[];

	/**
	 * @deprecated replaced by {@link ProductProductRecommendationList#orderNumber}
	 */
	orderNumber: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		uuid: string | undefined = uuidV4(),
		orderNumber: number,
		triggeringProductId: number,
		productRecommendations: ProductRecommendation[],
		recommendationText?: LanguageEntryTranslations | string,
		name?: string,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.uuid = uuid;
		this.orderNumber = orderNumber;
		this.triggeringProductId = triggeringProductId;
		this.productRecommendations = productRecommendations;
		this.recommendationText = recommendationText;
		this.name = name;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public get normalizedProductRecommendations(): ProductRecommendation[]
	{
		return normalizeProductRecommendations(this.productRecommendations);
	}

	public get hasTranslations(): boolean
	{
		return typeof this.recommendationText !== 'string';
	}

	public get recommendationTextRaw(): string | undefined
	{
		if (this.recommendationText === undefined)
			return undefined;

		if (this.hasTranslations)
			throw new IllegalStateException(`Recommendation text ${JSON.stringify(this.recommendationText)} is not a string`);

		return this.recommendationText as string;
	}
}

export const ProductRecommendationListProfile = SerializationProfile
	.create(ProductRecommendationList)
	.profile('productRecommendations', ProductRecommendationProfile)
	.transform('recommendationText', LanguageEntryTransformer);