import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ImageOverlay } from '../../../../../../Api/v3/model/story_post/content/overlay/ImageOverlay';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

const MaxDuration = 7000;

interface ImageOverlayViewerProps
{
	overlay: ImageOverlay;
	paused?: boolean;
}

export const ImageOverlayViewer: FC<ImageOverlayViewerProps> =
	({
		overlay,
		paused,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {onChangePlayedPercentage} = useContext(StoryPlayerContext);
		const {active, didLoad, onContinue} = useContext(StoryPostContext);

		const [startTime, setStartTime] = useState<number | undefined>();

		const getLoadedStoryPostFile = useObserver(() => businessStore.storyPostFileService.getLoadedStoryPostFile);

		const storyPostFile = useMemo(
			() =>
				didLoad
					? getLoadedStoryPostFile(overlay.fileId)
					: undefined,
			[didLoad, getLoadedStoryPostFile, overlay.fileId],
		);

		// Has to run every render
		const frameRequestCallbackHandle = active && !paused
			? window.requestAnimationFrame(
				now =>
				{
					if (startTime === undefined)
					{
						setStartTime(now);
					}
					else
					{
						const duration = (now - startTime);

						if (duration >= MaxDuration)
						{
							// noinspection JSIgnoredPromiseFromCall
							onContinue?.();
						}
						else
						{
							onChangePlayedPercentage(duration / MaxDuration);
						}
					}
				},
			)
			: undefined;

		useEffect(
			() =>
			{
				if (active)
					setStartTime(undefined);
			},
			[active],
		);

		useEffect(
			() =>
			{
				return () =>
				{
					if (frameRequestCallbackHandle !== undefined)
						window.cancelAnimationFrame(frameRequestCallbackHandle);
				};
			},
			[active, frameRequestCallbackHandle],
		);

		return <div
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,

				backgroundImage:
					storyPostFile
						? `url(${storyPostFile.fileUrl})`
						: undefined,
				backgroundPosition: `${overlay.position.x * 100}% ${overlay.position.y * 100}%`,
				backgroundSize: overlay.fit.toLowerCase(),
			}}
		/>;
	};
