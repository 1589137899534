import { OpenProductAction } from '../../../../../Api/v3/model/story_post/content/action/OpenProductAction';
import { ActionContext } from './ActionContext';

export async function performOpenProductAction(
	action: OpenProductAction,
	context: ActionContext,
): Promise<void>
{
	const product = context.businessStore.productByUuid.get(action.productId);

	if (product !== undefined)
	{
		context.pause();

		await context.businessStore.openProduct(
			product,
			true,
			false,
			context.resume,
		);
	}
}
