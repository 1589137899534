import { Collapse, FormControl, FormHelperText, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { isAfter, isToday } from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useCurrentMinute } from '../../../../../../Util/clock/useCurrentMinute';
import { formatValidationMessagesToText } from '../../../../../../Util/Validation';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { useCurrentOrderService } from '../../../BusinessContext';
import { SchedulerStore } from '../../SchedulerStore';
import { TimeSlot, toTimeSlot } from '../../TimeSlot';
import { useAllowedOrderingTimeSchedule } from '../../useAllowedOrderingTimeSchedule';
import { useAllowedOrderingTimeScheduleWithoutMinimum } from '../../useAllowedOrderingTimeScheduleWithoutMinimum';
import { SchedulerTimeSelectOption } from '../SchedulerTimeSelectOption';

const useStyles = makeStyles(theme => ({
	asapMenuItem: {
		fontStyle: 'italic',
		whiteSpace: 'normal',
	},
	asapValueLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	form: {
		marginBottom: theme.spacing(1),
	},
	noTimeAvailableLabel: {
		fontStyle: 'italic',
		whiteSpace: 'normal',
	},
	noTimeAvailableValueLabel: {
		fontSize: '0.75rem',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	noTimeAvailableMenuItem: {
		pointerEvents: 'none',
	},
	placeholderIcon: {
		marginRight: theme.spacing(1),
	},
	selectMenu: {
		display: 'flex',
	},
}));

export interface InitializedSchedulerTimeSelectProps
{
	day: string;
	onChange: (value: SchedulerTimeSelectOption | undefined) => void;
	showValidation: boolean;
	store: SchedulerStore;
	timeSlots: TimeSlot[];
	value: 'ASAP' | TimeSlot | undefined;
}

export const InitializedSchedulerTimeSelect: FC<InitializedSchedulerTimeSelectProps> =
	({
		day,
		onChange,
		showValidation,
		store,
		timeSlots,
		value,
	}) =>
	{
		const translate = useTranslate();
		const localizer = useLocalizer();
		const currentMinute = useCurrentMinute();
		const currentPlaceService = useCurrentPlaceService();
		const currentOrderService = useCurrentOrderService(true);

		const classes = useStyles();

		const allowedOrderingTimeScheduleWithoutMinimum = useAllowedOrderingTimeScheduleWithoutMinimum();
		const allowedOrderingTimeSchedule = useAllowedOrderingTimeSchedule();

		const destinationType = useObserver(() => currentOrderService.effectiveDestinationType);
		const formatLocalTime = useObserver(() => localizer.formatLocalTime);
		const orderInFutureSupport = useObserver(() => currentPlaceService.place?.orderInFutureSupport);
		const supportsOrderingAsSoonAsPossible = useObserver(() => currentPlaceService.place?.supportsOrderingAsSoonAsPossible);
		const timeValidationErrors = useObserver(() => store.timeValidationErrors);
		const violatedReports = useObserver(() => currentOrderService.violatedReports);

		const validationMessage = useMemo(
			() =>
			{
				const hasViolatingScheduledOrderLimitReports = (violatedReports ?? []).some(
					report =>
						report.type === 'ScheduledOrderLimit',
				);

				if (hasViolatingScheduledOrderLimitReports)
				{
					return formatValidationMessagesToText([
						translate('Order-Time-Invalid'),
						...timeValidationErrors,
					]);
				}
				else if (showValidation && timeValidationErrors.length > 0)
				{
					return formatValidationMessagesToText(timeValidationErrors);
				}
			},
			[showValidation, timeValidationErrors, translate, violatedReports],
		);

		const allowedTimeSlots = useMemo(
			() =>
				timeSlots
					.filter(
						timeSlot =>
							isAfter(timeSlot.start, currentMinute) &&
							isTrueAtInCache(allowedOrderingTimeSchedule, timeSlot.start),
					),
			[allowedOrderingTimeSchedule, currentMinute, timeSlots],
		);

		const handleChange = useCallback(
			(event: ChangeEvent<{ value: 'ASAP' | string }>) =>
			{
				if (event.target.value === 'ASAP')
				{
					onChange('ASAP');
				}
				else
				{
					onChange(
						JSON.parse(
							event.target.value,
						),
					);
				}
			},
			[onChange],
		);

		useEffect(
			() =>
			{
				if (value !== undefined && value !== 'ASAP' && !isTrueAtInCache(allowedOrderingTimeSchedule, value.start))
					onChange(undefined);
			},
			[allowedOrderingTimeSchedule, onChange, value],
		);

		const timeSlotOptions = useMemo<('' | 'ASAP' | TimeSlot)[]>(
			() =>
			{
				const extraOptions: ('' | 'ASAP' | TimeSlot)[] = [
					'',
				];

				if (
					supportsOrderingAsSoonAsPossible &&
					orderInFutureSupport !== 'DISALLOWED' &&
					isTrueAtInCache(allowedOrderingTimeScheduleWithoutMinimum, currentMinute) &&
					isToday(new Date(day))
				)
					extraOptions.push('ASAP');

				// Return all time slots, filter unavailable time slots at rendering.
				// Otherwise, selecting a time slot which becomes invalid after changes in the shopping cart results in an error.
				// The most notable source of these changes is the MobX-based write-ahead log in the current order service.
				return [
					...extraOptions,
					...timeSlots,
				];
			},
			[allowedOrderingTimeScheduleWithoutMinimum, currentMinute, day, orderInFutureSupport, supportsOrderingAsSoonAsPossible, timeSlots],
		);

		return <FormControl
			className={classes.form}
			fullWidth
		>
			<Select
				classes={{
					selectMenu: classes.selectMenu,
				}}
				disabled={allowedTimeSlots.length < 1}
				displayEmpty
				error={validationMessage !== undefined}
				onChange={handleChange}
				renderValue={
					(value: string) =>
					{
						if (value === '')
						{
							const hasNoTimeAvailable = allowedTimeSlots.length < 1;

							if (hasNoTimeAvailable)
							{
								return <Typography
									className={classes.noTimeAvailableValueLabel}
									color="textSecondary"
								>
									{translate('Order-Scheduling-No-Times-Available')}
								</Typography>;
							}
							else
							{
								return <>
									<ScheduleIcon
										className={classes.placeholderIcon}
										color="disabled"
									/>
									<Typography
										color="textSecondary"
									>
										{translate('Order-Time-Select')}...
									</Typography>
								</>;
							}
						}
						else if (value === 'ASAP')
						{
							return <Typography
								className={classes.asapValueLabel}
							>
								{translate('Order-Time-AsSoonAsPossible')}
							</Typography>;
						}
						else
						{
							const timeSlot = toTimeSlot(
								JSON.parse(
									value,
								),
							);

							return destinationType === 'ADDRESS'
								?
								<>
									<Typography>{formatLocalTime(timeSlot.start)}</Typography>
									<Typography>&nbsp;-&nbsp;</Typography>
									<Typography>{formatLocalTime(timeSlot.end)}</Typography>
								</>
								:
								<Typography>{formatLocalTime(timeSlot.start)}</Typography>;
						}
					}
				}
				value={
					value === undefined
						? ''
						: value === 'ASAP'
							? 'ASAP'
							: JSON.stringify(value)
				}
				variant="outlined"
			>
				{
					timeSlotOptions
						.map(
							timeSlot =>
							{
								if (timeSlot === '')
								{
									return <MenuItem
										className={classes.noTimeAvailableMenuItem}
										key="no-times-available"
										style={{
											display: 'none',
										}}
										value=""
									>
										<Typography
											className={classes.noTimeAvailableLabel}
											color="textSecondary"
										>
											{translate('Order-Scheduling-No-Times-Available')}
										</Typography>
									</MenuItem>;
								}
								else if (timeSlot === 'ASAP')
								{
									return <MenuItem
										className={classes.asapMenuItem}
										key="asap"
										value="ASAP"
									>
										<Typography>
											{translate('Order-Time-AsSoonAsPossible')}
										</Typography>
									</MenuItem>;
								}
								else
								{
									const startString = formatLocalTime(timeSlot.start);
									const endString = formatLocalTime(timeSlot.end);

									return <MenuItem
										key={`${startString}_${endString}`}
										style={{
											display: isAfter(timeSlot.start, currentMinute) && isTrueAtInCache(allowedOrderingTimeSchedule, timeSlot.start)
												? undefined
												: 'none',
										}}
										value={JSON.stringify(timeSlot)}
									>
										{
											destinationType === 'ADDRESS'
												?
												<>
													<Typography>{startString}</Typography>
													<Typography>&nbsp;-&nbsp;</Typography>
													<Typography>{endString}</Typography>
												</>
												:
												<Typography>{startString}</Typography>
										}
									</MenuItem>;
								}
							},
						)
				}
			</Select>
			<Collapse
				in={validationMessage !== undefined}
			>
				<FormHelperText
					error
				>
					{validationMessage}
				</FormHelperText>
			</Collapse>
		</FormControl>;
	};
