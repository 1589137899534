import Decimal from 'decimal.js';
import { Duration } from 'iso8601-duration';
import { makeAutoObservable, observable } from 'mobx';
import { shouldShowOnMenu } from '../../Component/Page/Business/Menu/Api/shouldShowOnMenu';
import { ClockService } from '../../Util/clock/ClockService';
import sanitizeHtml from '../../Util/Html/sanitizeHtml';
import { Place } from '../Business/Place';
import { Routing } from '../Business/Routing/Routing';
import { Color } from '../Other/Color';
import { TimeScheduleSpecification } from '../Util/time/TimeScheduleSpecification';
import { VatGroup } from '../vat_group/VatGroup';
import { AllergenInformation } from './AllergenInformation';
import { MenuEntryType } from './MenuEntryType';
import { NutritionInformation } from './NutritionInformation';
import { ProductCategory } from './ProductCategory';
import { ProductFeature } from './ProductFeature';
import { ProductFeatureAssignment } from './ProductFeatureAssignment';
import { ProductFeatureVariant } from './ProductFeatureVariant';
import { ProductFee } from './ProductFee';

export class Product
{
    // ------------------------- Properties -------------------------

    id: number;
    uuid: string;
    name: string;
    description: string;
    descriptionInHtml: boolean;
    category: ProductCategory;

    imageUrl: string;
    imageDefaultingToCategoryImage: boolean;
    imageHasTextBackdrop: boolean | undefined;
    imageIsTextContrastColorDark: boolean | undefined;
    imageTextBackdropColor: Color | undefined;
    imageTextColor: Color | undefined;
    imageBackgroundColor: Color | undefined;
    imageDoContain: boolean | undefined;

    externalId: string | undefined;
    scanCode: string | undefined;

    detailTitleOverPicture: boolean | undefined;

    menuEntryType: MenuEntryType | undefined;
    menuEntryColor: Color | undefined;
    menuEntryTextColor: Color | undefined;

    price: Decimal;
    feeIds: number[];
    fees = observable.array<ProductFee>();
    isAvailableForOrdering: boolean;

    /**
     * The quantity of this {@link Product} in stock
     */
    quantity?: number;
    isDirectOrder: boolean;
    isPriceHiddenWhenZero: boolean;
    allergenInformation: AllergenInformation;
    askStaffForAllergenInfo: boolean;
    nutritionInformation: NutritionInformation;
    featureAssignments: ProductFeatureAssignment[];
    menuCardId: number | undefined;
    routing?: Routing;
    public readonly hasEmbeddableHtmlAfterOrder: boolean;

    orderableAt: TimeScheduleSpecification;
    visibleAt: TimeScheduleSpecification;

    preparationTime: Duration;

    public readonly vatGroup: VatGroup | undefined;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    initialize(
        productById: Map<number, Product>,
        featureById: Map<number, ProductFeature>,
        assignmentById: Map<number, ProductFeatureAssignment>,
        variantById: Map<number, ProductFeatureVariant>,
        feeById: Map<number, ProductFee>,
        category: ProductCategory,
    )
    {
        productById.set(this.id, this);

        this.category = category;

        this.presentFeatureAssignments.forEach(
            assignment => assignmentById.set(assignment.id, assignment),
        );

        this.presentFeatureAssignments.forEach(
            assignment => assignment.initialize(featureById, assignmentById, variantById),
        );

        if (this.feeIds != null)
        {
            this.fees.replace(
                this.feeIds
                    .map(feeId => feeById.get(feeId))
                    .filter((t): t is ProductFee => t !== undefined)
            );
        }
    }

    // -------------------------- Computed --------------------------

    get presentFeatureAssignments(): ProductFeatureAssignment[]
    {
        return this
            ?.featureAssignments
            ?.filter(assignment => assignment.productFeature !== undefined) ?? []
    }

    get descriptionLines(): string[]
    {
        return this.description.split(/\n/);
    }

    get minPrice(): Decimal
    {
        return this.getMinOrMaxPrice(true);
    }

    get maxPrice(): Decimal
    {
        return this.getMinOrMaxPrice(false);
    }

    get hasPriceAlteration(): boolean
    {
        return !this.minPrice.eq(this.maxPrice);
    }

    get sanitizedHtmlDescription(): string | undefined
    {
        if (this.descriptionInHtml && this.description)
        {
            return sanitizeHtml(this.description);
        }
        else
        {
            return undefined;
        }
    }

    get isConfigurable(): boolean
    {
        return this.presentFeatureAssignments.length > 0;
    }

    get needsAgeVerification(): boolean
    {
        return this.nutritionInformation?.containsAlcohol
            ?? false;
    }

    // -------------------------- Actions ---------------------------

    public setQuantity(quantity: number): void
    {
        this.quantity = quantity;
    }

    public setPrice(price: Decimal): void
    {
        this.price = price;
    }

    // ------------------------ Public logic ------------------------

    isPriceHidden(price: Decimal)
    {
        return price.eq(0) && this.isPriceHiddenWhenZero;
    }

    getMinOrMaxPrice(isMinimum: boolean): Decimal
    {
        let minOrMaxPrice = this.price;

        this.presentFeatureAssignments.forEach(featuresAssignment =>
        {
            if (!featuresAssignment.isConditional)
            {
                minOrMaxPrice =
                    minOrMaxPrice.add(
                        featuresAssignment.getMinOrMaxPriceAlteration(isMinimum));
            }
        });

        if (minOrMaxPrice.lessThan(0))
        {
            return new Decimal(0);
        }
        else
        {
            return minOrMaxPrice;
        }
    }

    public isVisibleNow(
        place: Place,
        clockService: ClockService
    ): boolean
    {
        if (place.timeSchedulesIgnored)
            return true;
        else
            return shouldShowOnMenu({visibleAt: this.visibleAt}, clockService.currentMinute);
    }
}

