import { shouldShowOnMenu } from '../../Component/Page/Business/Menu/Api/shouldShowOnMenu';
import { ClockService } from '../../Util/clock/ClockService';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Color, ColorProfile } from '../Other/Color';
import { TimeScheduleSpecification } from '../Util/time/TimeScheduleSpecification';
import { AnnouncementAction } from './AnnouncementAction';
import { Place } from './Place';

export type AnnouncementType = 'standard' | 'success' | 'info' | 'warning' | 'danger' | 'critical';

export function getThemeColor(type: AnnouncementType): 'primary' | 'secondary' | 'error' | undefined
{
    switch (type)
    {
        case 'standard':
            return 'primary';

        case 'success':
            return 'primary';

        case 'info':
            return 'primary';

        case 'warning':
            return 'primary';

        case 'danger':
            return 'error';
    }

    return undefined;
}

export class Announcement
{
    // ------------------------- Properties -------------------------

    public readonly id: number | undefined;
    public readonly productIds: number[];
    public readonly productCategoryIds: number[];
    public readonly type: AnnouncementType;
    public readonly title: string | undefined;
    public readonly content: string;
    public readonly longContent: string | undefined;
    public readonly longContentInHtml: boolean | undefined;
    public readonly rightImageUrl: string | undefined;
    public readonly backgroundImageUrl: string | undefined;
    public readonly backgroundImageOpacity: number | undefined;
    public readonly backgroundColor: Color | undefined;
    public readonly backgroundContrastTextColor: Color | undefined;
    public readonly linkUrl: string | undefined;
    public readonly action: AnnouncementAction | undefined;
    public readonly isEnabled: boolean;
    public readonly isDisplayedInMenu: boolean;
    public readonly isDisplayedInProduct: boolean;
    public readonly requiresUserAcknowledgement: boolean;
    public readonly visibleAt: TimeScheduleSpecification;
    public readonly visibleAtTimeScheduleId?: number;

    // ------------------------ Constructor -------------------------

    constructor(
        id: number | undefined,
        type: AnnouncementType,
        content: string,
        isDisplayedInMenu: boolean,
        isDisplayedInProduct: boolean,
        isEnabled: boolean,
    )
    {
        this.id = id;
        this.type = type;
        this.content = content;
        this.isDisplayedInMenu = isDisplayedInMenu;
        this.isDisplayedInProduct = isDisplayedInProduct;
        this.isEnabled = isEnabled;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get colorIntention(): 'primary' | 'secondary' | 'error' | undefined
    {
        return getThemeColor(this.type);
    }

    get isAssignedToProduct(): boolean
    {
        return this.productIds.length > 0;
    }

    get isAssignedToProductCategory(): boolean
    {
        return this.productCategoryIds.length > 0;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public isVisibleNow(
        place: Place,
        clockService: ClockService
    ): boolean
    {
        if (place.timeSchedulesIgnored)
            return true;
        else
            return shouldShowOnMenu(this, clockService.currentMinute);
    }

    // ----------------------- Private logic ------------------------
}

export const AnnouncementProfile = SerializationProfile
    .create(Announcement)
    .profile('backgroundColor', ColorProfile)
    .profile('backgroundContrastTextColor', ColorProfile);
