import { ComponentType, CSSProperties, FC, MouseEventHandler, useContext, useRef } from 'react';
import { Element } from '../../../../Api/v3/model/story_post/content/element/Element';
import { StoryPostContent } from '../../../../Api/v3/model/story_post/content/StoryPostContent';
import { useElementDimensions } from '../../../../Util/element/useElementDimensions';
import { StoryPostContext } from '../../context/StoryPostContext';
import { StoriesPlayerContext } from '../context/StoriesPlayerContext';
import { ActionContext } from './action/ActionContext';
import { BackgroundViewer } from './background/BackgroundViewer';
import { StoryPostHeightProvider } from './context/StoryPostHeightProvider';
import { StoryPostWidthProvider } from './context/StoryPostWidthProvider';
import { ElementsViewer } from './ElementsViewer';
import { OverlayViewer } from './overlay/OverlayViewer';
import { StoryPostLoader } from './StoryPostLoader';

interface StoryPostViewerProps
{
	content: StoryPostContent;
	actionContext: ActionContext;
	ElementsViewerComponent?: ComponentType<{ elements: Element[], actionContext: ActionContext }>;
	onNavigateToPreviousPost?: MouseEventHandler<HTMLDivElement>;
	onNavigateToNextPost?: MouseEventHandler<HTMLDivElement>;
	style?: CSSProperties;
}

export const StoryPostViewer: FC<StoryPostViewerProps> =
	({
		content,
		actionContext,
		ElementsViewerComponent = ElementsViewer,
		onNavigateToPreviousPost,
		onNavigateToNextPost,
		style,
	}) =>
	{
		const {isPaused} = useContext(StoriesPlayerContext);
		const {didLoad} = useContext(StoryPostContext);

		const rootRef = useRef<HTMLDivElement>(null);

		const {width, height} = useElementDimensions(rootRef);

		return <div
			ref={rootRef}
			style={{
				position: 'relative',
				width: '100%',
				height: '100%',
				userSelect: 'none',
				WebkitUserSelect: 'none',
				...(style ?? {}),
			}}
		>
			<BackgroundViewer
				backgroundColor={content.backgroundColor}
			/>
			<OverlayViewer
				overlay={content.overlay}
				paused={isPaused}
			/>
			{
				width && height && didLoad &&
				<StoryPostWidthProvider.Provider
					value={width}
				>
					<StoryPostHeightProvider.Provider
						value={height}
					>
						<ElementsViewerComponent
							elements={content.elements}
							actionContext={actionContext}
						/>
					</StoryPostHeightProvider.Provider>
				</StoryPostWidthProvider.Provider>
			}
			{
				!didLoad &&
				<StoryPostLoader />
			}
			{
				onNavigateToPreviousPost &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: '50%',
						bottom: 0,
						left: 0,
						cursor: 'pointer',
						WebkitTapHighlightColor: 'transparent',
					}}
					onClick={onNavigateToPreviousPost}
				/>
			}
			{
				onNavigateToNextPost &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: '50%',
						cursor: 'pointer',
						WebkitTapHighlightColor: 'transparent',
					}}
					onClick={onNavigateToNextPost}
				/>
			}
		</div>;
	};
