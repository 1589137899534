// @ts-nocheck
/* eslint-disable */
import { CardContent, FormGroup, FormHelperText } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { formatValidationMessagesToText } from '../../../../../../Util/Validation';
import { FeatureConfigurationOptionGroup } from '../option_group/FeatureConfigurationOptionGroup';
import { FeatureConfigurationOptionGroupHeader } from '../option_group/header/FeatureConfigurationOptionGroupHeader';
import { ProductConfigurationStore } from '../ProductConfigurationStore';
import { FeatureConfigurationStore } from './FeatureConfigurationStore';
import { FeatureConfigurationWithImages } from './WithImages/FeatureConfigurationWithImages';

interface FeatureConfigurationProps
{
    store: FeatureConfigurationStore;
    productConfigurationStore: ProductConfigurationStore;
    hideTitleAndDescription?: boolean;
}

export const FeatureConfiguration: FC<FeatureConfigurationProps> =
    (
        {
            store,
            productConfigurationStore,
            hideTitleAndDescription,
        }
    ) =>
    {
        const initialize = useObserver(() => store.initialize);
        const deselectVariant = useObserver(() => store.deselectVariant);
        const productFeatureVariants = useObserver(() => store.productFeature.variants);

        useEffect(() =>
        {
            initialize().then();
        }, [store]);

        useEffect(() =>
        {
            return () =>
            {
                productFeatureVariants.forEach(variant => deselectVariant(variant));
            }
        } ,[deselectVariant, productFeatureVariants]);

        return useObserver(() => {
            const {
                isValid,
                initialized
            } = store;
            const {
                showValidation: showValidationInProduct
            } = productConfigurationStore;
            const {
                showValidation: showValidationInFeature
            } = store;
            const showValidation = showValidationInProduct || showValidationInFeature;
            if (!initialized)
            {
                return <></>;
            }
            return <CardContent
                style={{
                    backgroundColor:
                        store.hasAnyImage && showValidation && !isValid
                            ?
                            '#f001'
                            :
                            undefined,
                }}
            >
                {
                    !hideTitleAndDescription &&
                    <FeatureConfigurationOptionGroupHeader
                        store={store}
                    />
                }
                <FormGroup
                    style={{
                        // this is to ensure that the SwiperControl in the FeatureConfigurationWithImages
                        // has a correct height in the kiosk product drawer
                        display:
                            store.hasAnyImage
                                ? 'block'
                                : undefined,
                    }}
                >
                    {
                        store.hasAnyImage
                            ? <FeatureConfigurationWithImages
                                store={store}
                            />
                            : <FeatureConfigurationOptionGroup
                                hideHeader
                                size="Large"
                                store={store}
                            />
                    }
                    {
                        store.hasAnyImage && (showValidation || showValidationInFeature) && !isValid
                            ? <FormHelperText
                                error
                            >
                                {formatValidationMessagesToText(store.validationErrors)}
                            </FormHelperText>
                            : <></>
                    }
                </FormGroup>
            </CardContent>;
        })
    };
