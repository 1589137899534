import Decimal from 'decimal.js';
import { action, makeObservable, observable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Color } from '../Other/Color';

export class ProductFeatureVariant
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly uuid: string;

	readonly description?: string;
	readonly imageBackgroundColor?: Color;
	readonly imageBackdropColor?: Color;
	readonly imageDoContain?: boolean;
	readonly imageIsTextContrastColorDark?: boolean;
	readonly imageTextColor?: Color;
	readonly imageUrl?: string;
	readonly isSelectedByDefault: boolean;
	readonly name: string;

	price: Decimal;
	quantity?: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeObservable(this, {
			price: observable,
			quantity: observable,
			setPrice: action.bound,
			setQuantity: action.bound,
		});
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	public setQuantity(quantity: number | undefined): void
	{
		this.quantity = quantity;
	}

	public setPrice(price: Decimal): void
	{
		this.price = price;
	}
}

export const ProductFeatureVariantProfile = SerializationProfile
	.create(ProductFeatureVariant)
	.transform('price', NumberToDecimalTransformer);
