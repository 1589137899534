import { Account } from '../../../Api/Account/Account';
import { Client } from '../../../Bridge/Client/Client';
import { postAny } from '../../../Util/Api';
import { getBackendOSValue } from '../../../Util/Api/getBackendOSValue';

export function verifyAccountV2(client: Client, account: Account): Promise<boolean>
{
	return postAny(
		'/business/client/account/verify',
		{
			key: account.key,
			password: account.token,
			os: getBackendOSValue(client),
		},
	);
}
