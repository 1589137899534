import * as React from 'react';
import { Avatar, ListItem, ListItemText } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AllergenInformationKey } from '../../../../../Api/Product/AllergenInformation';
import { Localizer } from '../../../../../Bridge/Localization/Localizer';
import allergenImageCel from './Resource/allergen_cel.png';
import allergenImageCer from './Resource/allergen_cer.png';
import allergenImageCru from './Resource/allergen_cru.png';
import allergenImageEgg from './Resource/allergen_egg.png';
import allergenImageFis from './Resource/allergen_fis.png';
import allergenImageLup from './Resource/allergen_lup.png';
import allergenImageMil from './Resource/allergen_mil.png';
import allergenImageMol from './Resource/allergen_mol.png';
import allergenImageMus from './Resource/allergen_mus.png';
import allergenImageNut from './Resource/allergen_nut.png';
import allergenImagePea from './Resource/allergen_pea.png';
import allergenImageSes from './Resource/allergen_ses.png';
import allergenImageSoy from './Resource/allergen_soy.png';
import allergenImageSul from './Resource/allergen_sul.png';

export function getAllergenImage(allergen: AllergenInformationKey)
{
    switch (allergen)
    {
        case 'celery':
            return allergenImageCel;
        case 'cerealsContainingGluten':
            return allergenImageCer;
        case 'crustaceans':
            return allergenImageCru;
        case 'eggs':
            return allergenImageEgg;
        case 'fish':
            return allergenImageFis;
        case 'lupin':
            return allergenImageLup;
        case 'milk':
            return allergenImageMil;
        case 'molluscs':
            return allergenImageMol;
        case 'mustard':
            return allergenImageMus;
        case 'nuts':
            return allergenImageNut;
        case 'peanuts':
            return allergenImagePea;
        case 'sesameSeeds':
            return allergenImageSes;
        case 'soybeans':
            return allergenImageSoy;
        case 'sulphurDioxideAndSulphites':
            return allergenImageSul;
        default:
            return undefined;
    }
}

export interface AllergenProps
{
    localizer: Localizer;
    allergen: string;
}

@observer
export default class Allergen extends React.Component<AllergenProps>
{
    render(): React.ReactNode
    {
        return <ListItem
            style={{
                padding: 8,
            }}
        >
            <Avatar
                src={getAllergenImage(this.props.allergen)}
            />
            <ListItemText
                primary={this.props.localizer.translate(`Allergens-${(this.props.allergen.charAt(0).toUpperCase().concat(this.props.allergen.substr(1, 2)))}`)}
                primaryTypographyProps={{
                    color: 'textSecondary'
                }}
                style={{
                    padding: '0px 16px',
                }}
            />
        </ListItem>;
    }
}
