import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { ProductFeatureVariant } from '../../../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationOptionGroupSize } from '../../../option_group/FeatureConfigurationOptionGroupSize';

const useStyles = makeStyles({
	text: {
		color: 'rgb(0, 0, 0, 0.48)',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px',
	},
	textLarge: {
		fontSize: 17,
		lineHeight: '20px',
	},
});

interface FeatureConfigurationOptionDescriptionProps
{
	size: FeatureConfigurationOptionGroupSize;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionDescription: FC<FeatureConfigurationOptionDescriptionProps> =
	({
		size,
		variant,
	}) =>
	{
		const classes = useStyles();

		if (variant.description === undefined)
		{
			return null;
		}
		else
		{
			return <Typography
				className={
					clsx(
						classes.text,
						{
							[classes.textLarge]: size === 'Large',
						},
					)
				}
			>
				{variant.description}
			</Typography>;
		}
	};
