import { AxiosError } from 'axios';
import { Exception } from './Exception';
import { ValidationError } from './ValidationError';

export class ValidationException extends Exception
{
	readonly name: string = 'ValidationException';
	readonly data?: ValidationError[];

	// ------------------------ Constructor -------------------------

	constructor(cause?: AxiosError, message?: string)
	{
		super(
			message !== undefined
				? message
				: `Received ${cause?.response?.status ?? cause?.response?.statusText ?? cause?.code} requesting ${cause?.config.method?.toUpperCase()} "${cause?.config.url}"${cause?.response?.data ? `, data: ${JSON.stringify(cause.response.data)}` : ''}`,
			cause,
		);
		this.data = cause?.response?.data
	}
}