export const Allergens =
	['cel', 'cer', 'cru', 'egg', 'fis', 'lup', 'mil', 'mol', 'mus', 'nut', 'pea', 'ses', 'soy', 'sul'];

export const AllergenPresenceValues = [
	'Contains',
	'MayContainTraces',
] as const;

export type AllergenPresence = typeof AllergenPresenceValues[number];

export const AllergenInformationKeyValues = [
	'celery',
	'cerealsContainingGluten',
	'crustaceans',
	'eggs',
	'fish',
	'lupin',
	'milk',
	'molluscs',
	'mustard',
	'nuts',
	'peanuts',
	'sesameSeeds',
	'soybeans',
	'sulphurDioxideAndSulphites',
];

export type AllergenInformationKey = typeof AllergenInformationKeyValues[number];

export interface AllergenInformation
{
	celery?: AllergenPresence;
	cerealsContainingGluten?: AllergenPresence;
	crustaceans?: AllergenPresence;
	eggs?: AllergenPresence;
	fish?: AllergenPresence;
	lupin?: AllergenPresence;
	milk?: AllergenPresence;
	molluscs?: AllergenPresence;
	mustard?: AllergenPresence;
	nuts?: AllergenPresence;
	peanuts?: AllergenPresence;
	sesameSeeds?: AllergenPresence;
	soybeans?: AllergenPresence;
	sulphurDioxideAndSulphites?: AllergenPresence;
}