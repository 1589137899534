import { makeObservable, observable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { ProductFeatureType } from './ProductFeatureType';
import { ProductFeatureVariant, ProductFeatureVariantProfile } from './ProductFeatureVariant';

export class ProductFeature
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly type: ProductFeatureType;

	readonly description?: string;
	readonly name: string;
	readonly maxAllowedVariants: number;
	readonly minAllowedVariants: number | undefined;
	readonly variants: ProductFeatureVariant[];

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeObservable(this, {
			variants: observable,
		});
	}
}

export const ProductFeatureProfile = SerializationProfile
	.create(ProductFeature)
	.profile('variants', ProductFeatureVariantProfile);
