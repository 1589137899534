import { Decimal } from 'decimal.js';
import { makeObservable, observable } from 'mobx';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { Coupon } from './Coupon';

export class DiscountProductTotalFixedCoupon extends Coupon
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	amountOfDiscount: number;

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor(
		displayName: string,
		amountOfDiscount: number,
		id?: number,
	)
	{
		super('DISCOUNT_PRODUCT_TOTAL_FIXED', displayName, id);

		makeObservable(
			this,
			{
				amountOfDiscount: observable,
			},
		);

		this.amountOfDiscount = amountOfDiscount;
	}

	/*---------------------------------------------------------------*
	 *                        Initialization                         *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                           Computed                            *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                          Public logic                         *
	 *---------------------------------------------------------------*/

	effectDescriptionShort(
		localizer: Localizer,
		discount: number
	): string
	{
		return localizer
			.translate(
				'Discount-FixedDiscount',
				localizer.formatCurrency(new Decimal(this.amountOfDiscount), 'EUR')!,
			);
	}

	effectDescriptionLong(
		localizer: Localizer,
		discount: number
	): string
	{
		return localizer
			.translate(
				'Discount-FixedDiscountOffWholeOrder',
				localizer.formatCurrency(new Decimal(this.amountOfDiscount), 'EUR')!,
			);
	}

	/*---------------------------------------------------------------*
	 *                         Private logic                         *
	 *---------------------------------------------------------------*/
}
