import { Box, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupDescription } from './description/FeatureConfigurationOptionGroupDescription';
import { FeatureConfigurationOptionGroupTitle } from './title/FeatureConfigurationOptionGroupTitle';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		gap: 5,
		paddingRight: 20,
		paddingLeft: 20,
	},
});

interface FeatureConfigurationOptionGroupHeaderProps
{
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroupHeader: FC<FeatureConfigurationOptionGroupHeaderProps> =
	props =>
	{
		const classes = useStyles();

		return <Box
			className={classes.container}
		>
			<FeatureConfigurationOptionGroupTitle
				{...props}
			/>
			<FeatureConfigurationOptionGroupDescription
				{...props}
			/>
		</Box>;
	};
