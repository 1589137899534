import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { PostalCodeRestriction, PostalCodeRestrictionProfile } from './PostalCodeRestriction';

export class AddressRestriction
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	countryCode: string;
	postalCodes: PostalCodeRestriction[];

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}

export const AddressRestrictionProfile = SerializationProfile.create(AddressRestriction)
	.profile('postalCodes', PostalCodeRestrictionProfile);