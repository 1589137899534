import { createContext } from 'react';

interface StoryPlayerContextValue
{
	openPostIdx: number;
	onChangeOpenPostIdx: (value: number) => void;
	playedPercentage: number;
	onChangePlayedPercentage: (value: number) => void;
	didLoad: (postIdx: number) => boolean;
}

export const StoryPlayerContext = createContext<StoryPlayerContextValue>({
	openPostIdx: 0,
	onChangeOpenPostIdx: Promise.resolve,
	playedPercentage: 0,
	onChangePlayedPercentage: () => {},
	didLoad: () => true,
});
