import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';
import { AddProductToCartAction } from '../../../../../Api/v3/model/story_post/content/action/AddProductToCartAction';
import { ActionContext } from './ActionContext';

export async function performAddProductToCartAction(
	action: AddProductToCartAction,
	context: ActionContext,
): Promise<void>
{
	const product = context.businessStore.productByUuid.get(action.productId);

	if (product !== undefined)
	{
		if (product.isConfigurable)
		{
			context.pause();

			const productStore = await context.businessStore.openProduct(
				product,
				true,
				false,
				context.resume,
			);

			productStore.openConfigurationMode();
		}
		else
		{
			context.businessStore.addProductToShoppingCart(
				new ProductConfiguration(product),
				1,
				undefined,
			);
		}
	}
}
