import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useContext } from 'react';
import { NutritionFlag, NutritionFlagAliases } from '../../../../../../../Api/Product/NutritionFlag';
import { Product } from '../../../../../../../Api/Product/Product';
import { useComputed } from '../../../../../../../Util/Hooks/useComputed';
import { usePriceLabel } from '../../../../../../../Util/Hooks/usePriceLabel';
import { Badge } from '../../../../../../UI/Badge';
import MenuEntry from '../../../../../../UI/menu_entry/MenuEntry';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { NutritionFlagBadge } from '../../../../../Profile/NutritionFlagBadge';
import { BusinessContextRef } from '../../../../BusinessContext';
import { getAllergenImage } from '../../../../Product/Allergen/Allergen';
import { MixedGridItemType } from '../../MixedGrid/MixedGridItem';
import { ProductQuantityBadge } from './product_quantity_badge/ProductQuantityBadge';

interface ProductMenuEntryProps
{
	product: Product
	minHeight?: number
	minImageHeight?: number
	minImageWidth?: number
	forceGridItemType?: MixedGridItemType
	onClick?: (product: Product) => void
}

export const ProductMenuEntry: FC<ProductMenuEntryProps> =
	(
		{
			product,
			minHeight,
			minImageHeight,
			minImageWidth,
			forceGridItemType,
			onClick,
		}
	) =>
	{
		const imageUrl = useObserver(
			() => product.imageUrl
				?? (
					product.imageDefaultingToCategoryImage
						? product.category.imageUrl
						: undefined
				)
		);
		const {
			profileService,
		} = useContext(EntranceContextRef)
		const {
			businessStore,
		} = useContext(BusinessContextRef);
		const hasAllergen = useCallback(
			(allergen) => profileService.hasAllergen(allergen),
			[profileService]
		);
		const needsToSeeNutritionFlag = useCallback(
			(flag: NutritionFlag) => profileService.needsToSeeNutritionFlag(flag),
			[profileService]
		);
		const nutritionFlags = useObserver(() => product.nutritionInformation?.nutritionFlags ?? []);
		const priceLabel = usePriceLabel(product);
		const handleClick = useCallback(
			() => {
				if (onClick !== undefined)
					onClick(product);
				else
					businessStore.openProduct(product)
			},
			[businessStore, onClick, product]
		);
		const descriptionInHtml = useObserver(() => product.descriptionInHtml)
		const sanitizedHtmlDescription = useObserver(() => product.sanitizedHtmlDescription);
		const quantity =
			useComputed(
				() =>
					businessStore.currentOrderService.getQuantityByProduct(product),
				[businessStore]
			);
		const menuEntryType = forceGridItemType ?? product.menuEntryType;

		return useObserver(() => <MenuEntry
			image={imageUrl ? {
				imageURL: imageUrl,
				backgroundColor: product.imageBackgroundColor,
				containImage: product.imageDoContain,
				imageHasTextBackdrop: product.imageHasTextBackdrop,
				imageIsTextContrastColorDark: product.imageIsTextContrastColorDark,
				imageTextBackdropColor: product.imageTextBackdropColor,
				imageTextColor: product.imageTextColor,
			} : undefined}
			backgroundColor={product.menuEntryColor}
			textColor={product.menuEntryTextColor}
			title={product.name}
			description={product.description}
			type={menuEntryType}
			price={priceLabel}
			primaryBadges={
				quantity > 0
					? [
						<ProductQuantityBadge
							quantity={quantity}
						/>
					]
					: []
			}
			secondaryBadges={[
				...(Object.entries(product.allergenInformation))
					.filter(([allergen, ]) => hasAllergen(allergen))
					.map(([allergen,]) => <Badge
						key={allergen}
						size={18}
						backgroundImageUrl={getAllergenImage(allergen)}
					>
						{/* Put text in DOM so allergen badge is findable in tests */}
						<div
							style={{
								display: 'none',
							}}
						>
							{allergen}
						</div>
					</Badge>),
				...nutritionFlags
					.map(flag => NutritionFlagAliases.get(flag) ?? flag)
					.filter(nutritionFlag => needsToSeeNutritionFlag(nutritionFlag))
					.map(nutritionFlag => <div key={nutritionFlag}>
						<NutritionFlagBadge flag={nutritionFlag} size={18} />
						{/* Put text in DOM so allergen badge is findable in tests */}
						<div
							style={{
								display: 'none',
							}}
						>
							{nutritionFlag}
						</div>
					</div>),
			]}
			minHeight={minHeight}
			minImageHeight={minImageHeight}
			minImageWidth={minImageWidth}
			onClick={handleClick}
			descriptionInHtml={descriptionInHtml ? sanitizedHtmlDescription : undefined}
		/>);
	}
