import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { ProductFeatureVariant } from '../../../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationOptionGroupSize } from '../../../option_group/FeatureConfigurationOptionGroupSize';

const useStyles = makeStyles({
	text: {
		color: 'rgb(0, 0, 0)',
		fontSize: 16,
		fontWeight: 500,
		lineHeight: '19px',
	},
	textLarge: {
		fontSize: 20,
		lineHeight: '23px',
	},
});

interface FeatureConfigurationOptionNameProps
{
	size: FeatureConfigurationOptionGroupSize;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionName: FC<FeatureConfigurationOptionNameProps> =
	({
		size,
		variant,
	}) =>
	{
		const classes = useStyles();

		return <Typography
			className={
				clsx(
					classes.text,
					{
						[classes.textLarge]: size === 'Large',
					},
				)
			}
		>
			{variant.name}
		</Typography>;
	};
