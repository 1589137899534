import { LinearProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useContext, useEffect, useMemo } from 'react';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

const useStyles = makeStyles({
	bar: {
		backgroundColor: 'white',
		transitionDuration: '0s',
	},
	barActive: {
		transitionDuration: '0.2s',
	},
	root: {
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
		borderRadius: '1.5px',
		flexGrow: 1,
		height: 3,
		position: 'relative',

		'&[aria-valuenow="0"]': {
			"& > $bar": {
				transition: "none"
			}
		}
	},
});

interface StoryPlayerPostProgressProps
{
	active: boolean;
	completed: boolean;
}

export const StoryPlayerPostProgress: FC<StoryPlayerPostProgressProps> =
	({
		active,
		completed,
	}) =>
	{
		const {playedPercentage, onChangePlayedPercentage} = useContext(StoryPlayerContext);

		const classes = useStyles();

		const value = useMemo(
			() =>
				completed
					? 100
					: active
						? 100 * playedPercentage
						: -1,
			[active, completed, playedPercentage],
		);

		useEffect(
			() =>
			{
				return () =>
				{
					if (active)
						onChangePlayedPercentage(0);
				};
			},
			[active, onChangePlayedPercentage],
		);

		return <LinearProgress
			classes={{
				root: classes.root,
				bar: clsx(
					classes.bar,
					{
						[classes.barActive]: active,
					},
				),
			}}
			value={value}
			variant="determinate"
		/>;
	};
