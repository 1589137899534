import { makeAutoObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { Color } from '../Other/Color';
import { LanguageEntry } from '../Other/LanguageEntry';
import { MenuEntryType } from './MenuEntryType';
import { Product } from './Product';
import { ProductFeature } from './ProductFeature';
import { ProductFeatureAssignment } from './ProductFeatureAssignment';
import { ProductFeatureVariant } from './ProductFeatureVariant';
import { ProductFee } from './ProductFee';

export class ProductCategory
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	id: number;
	uuid: string = uuid();
	name: string;
	langName: LanguageEntry;
	imageUrl?: string;
	imageHasTextBackdrop?: boolean;
	imageIsTextContrastColorDark?: boolean;
	imageBackgroundColor?: Color;
	imageDoContain?: boolean;
	menuEntryType: MenuEntryType;
	menuEntryIsHighlighted: boolean;
	menuEntryColor?: Color;
	menuEntryTextColor?: Color;
	categories: ProductCategory[] = observable.array();
	products: Product[] = observable.array();
	fees: ProductFee[] = observable.array();
	features: ProductFeature[] = observable.array();
	parentCategory: ProductCategory | undefined;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	initialize(
		categoryById: Map<number, ProductCategory>,
		productById: Map<number, Product>,
		featureById: Map<number, ProductFeature>,
		assignmentById: Map<number, ProductFeatureAssignment>,
		variantById: Map<number, ProductFeatureVariant>,
		feeById: Map<number, ProductFee>,
		parentCategory: ProductCategory | undefined,
	)
	{
		categoryById.set(this.id, this);

		this.fees?.forEach(fee => feeById.set(fee.id, fee));

		this.features?.forEach(
			feature =>
			{
				featureById.set(feature.id, feature);
				feature.variants.forEach(variant => variantById.set(variant.id, variant));
			},
		);

		this.products?.forEach(
			product => product.initialize(
				productById,
				featureById,
				assignmentById,
				variantById,
				feeById,
				this,
			),
		);

		this.categories?.forEach(
			category =>
				category.initialize(
					categoryById,
					productById,
					featureById,
					assignmentById,
					variantById,
					feeById,
					this,
				),
		);

		this.parentCategory = parentCategory;
	}

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/**
	 * Gets the total number of {@link Product} inside this {@link ProductCategory} and all child {@link ProductCategory}.
	 */
	public get totalNumberOfProducts(): number
	{
		return this
			.categories
			.map(category => category.totalNumberOfProducts)
			.reduce((prev, current) => prev + current, this.products.length);
	}

	public getParentCategories(): ProductCategory[]
	{
		if (this.parentCategory === undefined)
			return [this];
		else
			return [...this.parentCategory.getParentCategories(), this];
	}

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}
