import { Box, Collapse, makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { formatValidationMessagesToText } from '../../../../../../../Util/Validation';
import { FeatureConfigurationStore } from '../../FeatureConfiguration/FeatureConfigurationStore';

const useStyles = makeStyles({
	collapse: {
		marginBottom: -9,
	},
	container: {
		marginBottom: 9,
		paddingRight: 20,
		paddingBottom: 9,
		paddingLeft: 20,
	},
});

interface FeatureConfigurationOptionGroupValidationMessagesProps
{
	showValidation?: boolean;
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroupValidationMessages: FC<FeatureConfigurationOptionGroupValidationMessagesProps> =
	({
		showValidation,
		store,
	}) =>
	{
		const [text, setText] = useState('');

		const classes = useStyles();

		const validationErrors = useObserver(() => store.validationErrors);

		const handleExited = useCallback(
			() =>
			{
				if (validationErrors.length === 0)
					setText('');
			},
			[validationErrors.length],
		);

		useEffect(
			() =>
			{
				if (validationErrors.length > 0)
					setText(formatValidationMessagesToText(validationErrors));
			},
			[validationErrors],
		);

		return <Collapse
			className={classes.collapse}
			in={showValidation && validationErrors.length > 0}
			onExited={handleExited}
		>
			<Box
				className={classes.container}
			>
				<Typography
					color="error"
					variant="caption"
				>
					{text}
				</Typography>
			</Box>
		</Collapse>;
	};
