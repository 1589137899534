import { FC, useContext, useEffect, useState } from 'react';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

const MaxDuration = 7000;

interface NoopOverlayViewerProps
{
	paused?: boolean;
}

export const NoopOverlayViewer: FC<NoopOverlayViewerProps> =
	({
		paused,
	}) =>
	{
		const {onChangePlayedPercentage} = useContext(StoryPlayerContext);
		const {active, onContinue} = useContext(StoryPostContext);

		const [startTime, setStartTime] = useState<number | undefined>();

		// Has to run every render
		const frameRequestCallbackHandle = active && !paused
			? window.requestAnimationFrame(
				now =>
				{
					if (startTime === undefined)
					{
						setStartTime(now);
					}
					else
					{
						const duration = (now - startTime);

						if (duration >= MaxDuration)
						{
							// noinspection JSIgnoredPromiseFromCall
							onContinue?.();
						}
						else
						{
							onChangePlayedPercentage(duration / MaxDuration);
						}
					}
				},
			)
			: undefined;

		useEffect(
			() =>
			{
				if (active)
					setStartTime(undefined);
			},
			[active],
		);

		useEffect(
			() =>
			{
				return () =>
				{
					if (frameRequestCallbackHandle !== undefined)
						window.cancelAnimationFrame(frameRequestCallbackHandle);
				};
			},
			[active, frameRequestCallbackHandle],
		);

		return null;
	};
