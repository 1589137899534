import { useContext } from 'react';
import { useEventCallback } from '../../../../../../Util/Event/useEventCallback';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';

export function useClientOnError(): (state: Error) => void
{
	const {businessStore} = useContext(BusinessContextRef);

	return useEventCallback(
		(error: Error) =>
			businessStore.handlePaymentProcessingResult?.({
				error,
				state: 'Failed',
			}),
	);
}
