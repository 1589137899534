import { makeAutoObservable, observable } from 'mobx';
import { RefObject } from 'react';
import { Fetch } from '../../Api/v3/fetch/Fetch';
import { fetchJson } from '../../Api/v3/fetch/fetchJson';
import { HateoasFont } from '../../Api/v3/model/font/HateoasFont';
import { isBusinessFont } from '../../Api/v3/model/story_post/content/shared/font/Font';
import { HateoasStoryPost } from '../../Api/v3/model/story_post/HateoasStoryPost';
import { Localizer } from '../../Bridge/Localization/Localizer';
import { getV3ApiUrl } from '../../Util/Api/Resources/getV3ApiUrl';

export class FontService
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly localizer: Localizer;

	private readonly fontById = observable.map<string, HateoasFont>();

	private readonly fontLoaderById = observable.map<string, Promise<HateoasFont>>();

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor(
		localizer: Localizer,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.localizer = localizer;
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	public getLoadedFont(id: string): HateoasFont | undefined
	{
		return this.fontById.get(id);
	}

	public async loadFont(fetch: Fetch, id: string): Promise<HateoasFont>
	{
		const existingValue = this.fontById.get(id);

		if (existingValue === undefined)
		{
			const resultPromise = fetchJson<HateoasFont>(
				fetch,
				getV3ApiUrl(`/fonts/${id}`),
			);

			this.fontLoaderById.set(id, resultPromise);

			const result = await resultPromise;

			const fontFace = new FontFace(
				result.family,
				`url(${result.url})`,
			);

			await fontFace.load();

			// @ts-ignore
			if (!document.fonts.has(fontFace))
			{
				// @ts-ignore
				document.fonts.add(fontFace);
			}

			this.fontById.set(result.id, result);
			this.fontLoaderById.delete(id);

			return result;
		}
		else
		{
			return existingValue;
		}
	}

	public async loadFonts(fetch: Fetch, post: HateoasStoryPost, containerRef: RefObject<HTMLDivElement>): Promise<void>
	{
		await Promise.all(
			post
				.content
				.elements
				.filter(element => element.type === 'Text' || element.type === 'TextButton')
				.map(element => element.textFont)
				.filter(isBusinessFont)
				.map(
					async font =>
					{
						const loadedFont = this.getLoadedFont(font.fontId);

						if (loadedFont === undefined)
						{
							const loadingPromise = this.fontLoaderById.get(font.fontId);

							if (loadingPromise === undefined)
							{
								return this.loadFont(fetch, font.fontId);
							}
							else
							{
								return loadingPromise;
							}

						}
						else
						{
							return loadedFont;
						}
					},
				),
		);

		await Promise.all(
			post
				.content
				.elements
				.filter(element => element.type === 'Text' || element.type === 'TextButton')
				.map(
					async element =>
					{
						// Texts using the default font require the corresponding font file to be preloaded
						// The font face is dependent on the font weight, font style & content (due to Unicode ranges)
						// We let the browser figure this out and load the correct font file for us
						if (!isBusinessFont(element.textFont))
						{
							const htmlElement = document.createElement('p');
							htmlElement.textContent = this.localizer.translateLanguageEntry(element.text);

							if (element.textFormat.includes('Bold'))
								htmlElement.style.setProperty('font-weight', '700');

							if (element.textFormat.includes('Italic'))
								htmlElement.style.setProperty('font-style', 'italic');

							containerRef.current!.appendChild(htmlElement);
						}
					},
				),
		);
	}
}
