import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ServiceForm } from '../../../Delivery/ServiceForm';
import { OrderBuilderExpansionPanel } from '../../core/OrderBuilderExpansionPanel';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface ServiceOrderBuilderSegmentProps
{
	store: OrderBuilderStore
}

export const ServiceOrderBuilderSegment: FC<ServiceOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const hasDeliveryForm = useObserver(() => store.hasDeliveryForm);
		if (!hasDeliveryForm)
			return null;
		return <Inner store={store}/>
	};

export const Inner: FC<ServiceOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const showValidation = useObserver(() => store.showValidation);
		const summaryLabel = useObserver(() => store.clientNameStore.summaryLabel);
		const clientNameHasFocus = useObserver(() => store.clientNameHasFocus);
		const setClientNameHasFocus = useObserver(() => store.setClientNameHasFocus);
		const summaryTypeValue = useObserver(() => store.clientNameStore.summaryTypeValue)
		const summaryBuzzerNumberValue = useObserver(() => store.clientNameStore.summaryBuzzerNumber);
		const summaryNameValue = useObserver(() => store.clientNameStore.summaryNameValue);
		const summaryAddressValue = useObserver(() => store.clientNameStore.summaryAddressValue);
		const summaryPhoneNumberValue = useObserver(() => store.clientNameStore.summaryPhoneNumberValue);
		const summaryEmailValue = useObserver(() => store.clientNameStore.summaryEmailValue);
		const clientNameStore = useObserver(() => store.clientNameStore);
		const clientNameStoreValidationLength = useObserver(() => clientNameStore.validation.length);
		const summary = useMemo(
			() => <div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'start',
				}}
			>
				<Typography
					variant={'body2'}
				>
					{summaryLabel}
				</Typography>
				<div
					style={{
						flex: '1 1 auto',
						overflowX: 'hidden',
						marginLeft: 16,
					}}
				>
					{
						summaryTypeValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{summaryTypeValue}
						</Typography>
					}
					{
						summaryBuzzerNumberValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<NotificationsIcon
								fontSize="inherit"
								style={{
									verticalAlign: 'middle',
									marginRight: 3,
									marginBottom: 3,
								}}
							/>
							{summaryBuzzerNumberValue}
						</Typography>
					}
					{
						summaryNameValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{summaryNameValue}
						</Typography>
					}
					{
						summaryAddressValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{summaryAddressValue}
						</Typography>
					}
					{
						summaryPhoneNumberValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								whiteSpace: 'nowrap',
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<PhoneIcon
								fontSize="inherit"
								style={{
									verticalAlign: 'middle',
									marginRight: 3,
									marginBottom: 3,
								}}
							/>
							{summaryPhoneNumberValue}
						</Typography>
					}
					{
						summaryEmailValue &&
						<Typography
							variant={'body1'}
							color="textSecondary"
							align="right"
							style={{
								display:
									clientNameHasFocus
										?
										'none'
										:
										undefined,
								whiteSpace: 'nowrap',
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							<EmailIcon
								fontSize="inherit"
								style={{
									verticalAlign: 'middle',
									marginRight: 3,
									marginBottom: 1.6,
								}}
							/>
							{summaryEmailValue}
						</Typography>
					}
				</div>
			</div>,
			[clientNameHasFocus, summaryAddressValue, summaryBuzzerNumberValue, summaryEmailValue, summaryLabel, summaryNameValue, summaryPhoneNumberValue, summaryTypeValue],
		);
		return <Grid
			item
			xs={12}
			style={{
				flex: '0 0 auto',
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'hidden',
			}}
		>
			<OrderBuilderExpansionPanel
				expanded={clientNameHasFocus}
				onExpandedChange={setClientNameHasFocus}
				summary={summary}
				error={showValidation && clientNameStoreValidationLength > 0}
			>
				<ServiceForm store={clientNameStore} />
			</OrderBuilderExpansionPanel>
		</Grid>;
	};
