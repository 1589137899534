import { ButtonBase, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';
import { ProductFeatureVariant } from '../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationStore } from '../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupSize } from '../option_group/FeatureConfigurationOptionGroupSize';
import { FeatureConfigurationOptionCaption } from './caption/FeatureConfigurationOptionCaption';
import { FeatureConfigurationOptionPrice } from './price/FeatureConfigurationOptionPrice';
import { FeatureConfigurationOptionSelector } from './selector/FeatureConfigurationOptionSelector';

const useStyles = makeStyles(theme => ({
		container: {
			alignItems: 'unset',
			display: 'flex',
			flexGrow: 1,
			gap: 12,
			paddingRight: 20,
			paddingLeft: 20,
			textAlign: 'unset',
			transition: theme.transitions.create('background-color', {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.shortest,
			}),
			width: '100%',
		},
		containerVisibleFocus: {
			backgroundColor: theme.palette.action.focus,
		},
	}
));

interface FeatureConfigurationOptionProps
{
	size: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOption: FC<FeatureConfigurationOptionProps> =
	({
		store,
		variant,
		...other
	}) =>
	{
		const classes = useStyles();

		const selected = useObserver(
			() =>
				store.selectedVariants.has(variant),
		);

		const handleClick = useCallback(
			() =>
				store.toggleSelection(variant),
			[store, variant],
		);

		// Only run on mount
		useEffect(
			() =>
			{
				if (variant.isSelectedByDefault && !selected)
					store.toggleSelection(variant);
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		);

		return <ButtonBase
			className={classes.container}
			focusVisibleClassName={classes.containerVisibleFocus}
			onClick={handleClick}
		>
			<FeatureConfigurationOptionSelector
				store={store}
				variant={variant}
				{...other}
			/>
			<FeatureConfigurationOptionCaption
				variant={variant}
				{...other}
			/>
			<FeatureConfigurationOptionPrice
				store={store}
				variant={variant}
				{...other}
			/>
		</ButtonBase>;
	};
