import { createContext } from 'react';
import { HateoasStoryPost } from '../../../Api/v3/model/story_post/HateoasStoryPost';
import { StoryPostSize } from '../util/StoryPostSize';

interface StoryPostContextValue
{
	post: HateoasStoryPost;
	active: boolean;
	loop?: boolean;
	onContinue?: () => Promise<void>;
	size: StoryPostSize;
	didLoad: boolean;
}

export const StoryPostContext = createContext<StoryPostContextValue>(undefined!);
