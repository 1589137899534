import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ProductFeatureVariant } from '../../../../../../../Api/Product/ProductFeatureVariant';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { useCurrentPlaceService } from '../../../../../../current-place-service/CurrentPlaceService';
import { FeatureConfigurationStore } from '../../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupSize } from '../../option_group/FeatureConfigurationOptionGroupSize';

const useStyles = makeStyles({
	container: {
		height: 18,
		paddingTop: 3,
	},
	text: {
		color: 'rgb(0, 0, 0)',
		fontSize: 12,
		fontWeight: 500,
		lineHeight: '15px',
	},
	textLarge: {
		fontSize: 20,
		lineHeight: '23px',
	},
});

interface FeatureConfigurationOptionPriceProps
{
	size: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionPrice: FC<FeatureConfigurationOptionPriceProps> =
	({
		size,
		store,
		variant,
	}) =>
	{
		const localizer = useLocalizer();
		const currentPlaceService = useCurrentPlaceService();

		const price = useObserver(() => variant.price);
		const hideCurrencySymbols = useObserver(() => currentPlaceService.place!.productCurrencySymbolsHidden);

		const doShowPrice = useMemo(
			() =>
				store.doShowPrice(variant),
			[store, variant],
		);

		const classes = useStyles();

		if (doShowPrice)
		{
			return <Box
				className={classes.container}
			>
				<Typography
					className={
						clsx(
							classes.text,
							{
								[classes.textLarge]: size === 'Large',
							},
						)
					}
				>
					{
						hideCurrencySymbols
							? localizer.formatCurrencyWithoutSymbol(price, store.productCurrencyCode)
							: localizer.formatCurrency(price, store.productCurrencyCode)
					}
				</Typography>
			</Box>;
		}
		else
		{
			return null;
		}
	};
