import { Box, Fade, makeStyles } from '@material-ui/core';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { ProductFeatureVariant } from '../../../../../../../../Api/Product/ProductFeatureVariant';
import { FeatureConfigurationStore } from '../../../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupSize } from '../../../option_group/FeatureConfigurationOptionGroupSize';

const useStyles = makeStyles(theme => ({
	container: {
		fontSize: 0,
		position: 'relative',
	},
	radioButton: {
		color: 'rgb(200, 200, 200)',
		height: 20,
		padding: 0,
		width: 20,
	},
	radioButtonLarge: {
		height: 24,
		width: 24,
	},
	radioButtonSelected: {
		position: 'absolute',
		top: 0,
		left: 0,

		// TODO: Switch color when we no longer brand the product configuration page
		// color: 'rgb(0, 0, 0)',
		color: theme.palette.primary.main,
	},
}));

interface FeatureConfigurationOptionRadioButtonProps
{
	size: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
	variant: ProductFeatureVariant;
}

export const FeatureConfigurationOptionRadioButton: FC<FeatureConfigurationOptionRadioButtonProps> =
	({
		size,
		store,
		variant,
	}) =>
	{
		const selected = useObserver(
			() =>
				store.selectedVariants.has(variant),
		);

		const classes = useStyles();

		return <Box
			className={classes.container}
		>
			<Fade
				in={!selected}
			>
				<RadioButtonUncheckedOutlinedIcon
					className={
						clsx(
							classes.radioButton,
							{
								[classes.radioButtonLarge]: size === 'Large',
							},
						)
					}
				/>
			</Fade>
			<Fade
				in={selected}
			>
				<RadioButtonCheckedOutlinedIcon
					className={
						clsx(
							classes.radioButton,
							classes.radioButtonSelected,
							{
								[classes.radioButtonLarge]: size === 'Large',
							},
						)
					}
				/>
			</Fade>
		</Box>;
	};
