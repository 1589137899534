import { FC, PropsWithChildren, useRef } from 'react';

const DefaultWidth = 200;

interface StoryPostContainerProps
{
	width?: number;
	fullScreen?: boolean;
}

export const StoriesPlayerContainer: FC<PropsWithChildren<StoryPostContainerProps>> =
	({
		fullScreen,
		width = DefaultWidth,
		children,
	}) =>
	{
		const rootRef = useRef<HTMLDivElement>(null);

		return <div
			ref={rootRef}
			style={{
				width: fullScreen
					? '100%'
					: width,
				height: fullScreen
					? '100%'
					: undefined,
				aspectRatio: fullScreen
					? undefined
					: '9 / 16',
				backgroundColor: fullScreen
					? 'black'
					: undefined,
			}}
		>
			{children}
		</div>;
	};
