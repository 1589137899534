import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useRootContext } from '../../../../../../RootContext';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { useIsRoutingByIdLive } from '../../../../../routing-state-provider/useIsRoutingByIdLive';
import { BusinessContextRef } from '../../../BusinessContext';

export function useShowRequestBillButton()
{
	const {businessStore} = useContext(BusinessContextRef);
	const {place} = useCurrentPlaceService();
	const {clockService} = useRootContext(true);
	const currentMinute = useObserver(() => clockService.currentMinute);

	const isOrderBillSupported = useObserver(() => place.isOrderBillSupported);
	const requestBillRoutingId = useObserver(() => place?.requestBillRoutingId);
	const isRoutingLive = useIsRoutingByIdLive(requestBillRoutingId);
	const isRoutingStatusIgnored = useObserver(() => place?.routingStatusIgnored);
	const hasNoRoutingOrRoutingIsLive = requestBillRoutingId === undefined || isRoutingLive || isRoutingStatusIgnored;

	const timeSchedule = useObserver(() =>
	{
		if (place?.requestBillTimeScheduleId === undefined)
			return null;
		else
			return businessStore.timeScheduleById.get(place.requestBillTimeScheduleId)?.booleanTimeSeries;
	});

	const isVisibleInTimeSchedule = useMemo(
		() =>
		{
			if (timeSchedule === null)
				return true;
			else if (timeSchedule === undefined)
				return false;
			else
				return isTrueAtInCache(timeSchedule, currentMinute);
		},
		[currentMinute, timeSchedule],
	);

	return isOrderBillSupported && hasNoRoutingOrRoutingIsLive && isVisibleInTimeSchedule;
}