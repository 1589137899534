import { Duration } from 'iso8601-duration';
import { makeAutoObservable } from 'mobx';
import { SerializationProfile, StringToDurationTransformer } from '../../Util/Serialization/Serialization';

export class OrderRestrictionConfiguration
{
	public readonly timeWindow: Duration;
	includedPlaceIds: number[] | undefined;
	excludedPlaceIds: number[] | undefined;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}
}

export const OrderRestrictionConfigurationProfile = SerializationProfile
	.create(OrderRestrictionConfiguration)
	.transform('timeWindow', StringToDurationTransformer);