import { Avatar, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Decimal from 'decimal.js';
import * as React from 'react';
import { CSSProperties, FC, MouseEvent, useCallback, useMemo } from 'react';
import { ProductOrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { UIColors } from '../../../../../../Constants/UIColors';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';

const AvatarSize = 32;

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            avatar:
                {
                    alignSelf: 'flex-start',
                    height: AvatarSize,
                    width: AvatarSize,
                },
            descriptionContainer:
                {
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: AvatarSize,
                },
            descriptionText:
                {
                    marginLeft: theme.spacing(1),
                },
            descriptionTextAligned:
                {
                    marginLeft: AvatarSize + theme.spacing(1),
                },
            infoContainer:
                {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            priceContainer:
                {
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    display: 'flex',
                    height: AvatarSize,
                    marginLeft: theme.spacing(1),
                },
            priceText:
                {
                },
            root:
                {
                    alignItems: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                },
            variantsContainer:
                {
                    marginLeft: theme.spacing(3),
                },
            variantsContainerAligned:
                {
                    marginLeft: AvatarSize + theme.spacing(3),
                },
            variantText:
                {
                    color: UIColors.greyDark,
                },
            variantPriceText:
                {
                    color: UIColors.greyDark,
                    marginLEft: theme.spacing(1),
                },
        }));

export interface OrderLineExpandedProps
{
    alignWithAvatars?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    orderLine: ProductOrderLineDescriptor;
    showPriceAsCurrency?: string;
    style?: CSSProperties;
}

export const ProductOrderLineExpanded: FC<OrderLineExpandedProps> =
    ({
         alignWithAvatars,
         onClick,
         orderLine,
         showPriceAsCurrency,
         style,
     }) =>
    {
        const classes = useStyles();

        const localizer = useLocalizer();

        const descriptionTextClassString = useMemo(
            () =>
            {
                if (orderLine.imageUrl === undefined && alignWithAvatars)
                {
                    return classes.descriptionTextAligned;
                }
                else
                {
                    return classes.descriptionText;
                }
            },
            [alignWithAvatars, classes.descriptionText, classes.descriptionTextAligned, orderLine.imageUrl]);


        const variantsContainerTextString = useMemo(
            () =>
            {
                if (alignWithAvatars)
                {
                    return classes.variantsContainerAligned;
                }
                else
                {
                    return classes.variantsContainer;
                }
            },
            [alignWithAvatars, classes]);

        const onUserClick = useCallback(
            (event: MouseEvent<HTMLDivElement>) =>
            {
                if (orderLine.variants.length > 0 && onClick !== undefined)
                {
                    onClick(event);
                    event.stopPropagation();
                }
            },
            [onClick, orderLine.variants]);

        return <div
            className={classes.root}
            onClick={onUserClick}
            style={style}
        >
            <div
                className={classes.infoContainer}
            >
                <div
                    className={classes.descriptionContainer}
                >
                    {
                        orderLine.imageUrl !== undefined &&
                        <Avatar
                            classes={{
                                root: classes.avatar,
                            }}
                            src={getFileUrl(`/image/${AvatarSize}/${AvatarSize}/Cover/High${orderLine.imageUrl}`)}
                        />
                    }
                    <Typography
                        classes={{
                            root: descriptionTextClassString,
                        }}
                    >
                        {orderLine.quantity}x&nbsp;{orderLine.description}
                    </Typography>
                </div>
                {
                    showPriceAsCurrency &&
                    orderLine.quantity > 0 &&
                    orderLine.price > 0 &&
                    <div
                        className={classes.priceContainer}
                    >
                        <Typography
                            classes={{
                                root: classes.priceText,
                            }}
                        >
                            {localizer.formatCurrency(new Decimal(orderLine.quantity * orderLine.price), showPriceAsCurrency)}
                        </Typography>

                    </div>
                }
            </div>
            {
                orderLine.variants.length > 0 &&
                <div
                    className={variantsContainerTextString}
                >
                    {
                        orderLine.variants.map(variant => <div
                            className={classes.infoContainer}
                            key={variant.id}
                        >
                            <Typography
                                classes={{
                                    root: classes.variantText,
                                }}
                            >
                                {variant.description}
                            </Typography>
                            {
                                showPriceAsCurrency &&
                                orderLine.quantity > 0 &&
                                variant.price !== undefined &&
                                variant.price > 0 &&
                                <Typography
                                    classes={{
                                        root: classes.variantPriceText,
                                    }}
                                >
                                    {localizer.formatCurrency(new Decimal(orderLine.quantity * variant.price), showPriceAsCurrency)}
                                </Typography>
                            }
                        </div>)
                    }
                </div>
            }
        </div>;
    };
