import { makeAutoObservable } from 'mobx';
import { UnsupportedOperationException } from '../../../Util/Exception/UnsupportedOperationException';
import { Coupon } from './Coupon';
import { DiscountProductTotalFixedCoupon } from './DiscountProductTotalFixedCoupon';
import { DiscountProductTotalQuantityCoupon } from './DiscountProductTotalQuantityCoupon';
import { DiscountProductTotalRelativeCoupon } from './DiscountProductTotalRelativeCoupon';

export type CouponType = 'DISCOUNT_PRODUCT_TOTAL_FIXED' | 'DISCOUNT_PRODUCT_TOTAL_RELATIVE' | 'DISCOUNT_PRODUCT_TOTAL_QUANTITY';

export class CouponDescriptor
{
    id: number;
    displayName: string;
    type: CouponType;
    value: number;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    get coupon(): Coupon
    {
        if (this.type === 'DISCOUNT_PRODUCT_TOTAL_RELATIVE')
        {
            return new DiscountProductTotalRelativeCoupon(
                this.displayName,
                this.value,
                this.id,
            );
        }
        else if (this.type === 'DISCOUNT_PRODUCT_TOTAL_FIXED')
        {
            return new DiscountProductTotalFixedCoupon(
                this.displayName,
                this.value,
                this.id,
            );
        }
        else if (this.type === 'DISCOUNT_PRODUCT_TOTAL_QUANTITY')
        {
            return new DiscountProductTotalQuantityCoupon(
                this.displayName,
                this.value,
                this.id,
            );
        }

        throw new UnsupportedOperationException();
    }
}
