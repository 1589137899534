import { Loader } from '@intentic/ts-foundation';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { formatValidationMessagesToText } from '../../../../Util/Validation';
import { EmailForm } from '../../../UI/Form/EmailForm/EmailForm';
import { PhoneNumberInput } from '../../../UI/Form/PhoneNumberInput/PhoneNumberInput';
import { BusinessContextRef } from '../BusinessContext';
import { OrderDestinationAddressForm } from '../OrderDestinationAddressForm';
import { DeliveryFormStore } from './DeliveryFormStore';

export interface NameFormProps
{
    store: DeliveryFormStore;
}

export const ServiceForm: FC<NameFormProps> =
    (
        {
            store
        }
    ) =>
    {
        const {businessStore} = useContext(BusinessContextRef);

        const place = useObserver(() => store.currentPlaceService.place!);
        const currentOrderService = useObserver(() => store.currentOrderService);
        const supportedDestinationTypes = useObserver(() => place.supportedDestinationTypes);
        const businessCountry = useObserver(() => businessStore.business.address.country);

        const localizer = useLocalizer();
        const [destinationType, setDestinationType] = useObserver(
            () => ([
                currentOrderService.effectiveDestinationType,
                currentOrderService.setDestinationType,
            ])
        );
        const [destinationAddress, setDestinationAddress, destinationAddressInitialized] = useObserver(
            () => ([
                currentOrderService.destinationAddress.get(),
                currentOrderService.destinationAddress.set,
                currentOrderService.destinationAddress.initialized
            ])
        );

        const setAddressValidation = useObserver(() => store.setAddressValid);
        const supportsDestinationTypePlace = useObserver(() => store.currentPlaceService.place!.supportsDestinationTypePlace);
        const supportsDestinationTypePickupPoint = useObserver(() => store.currentPlaceService.place!.supportsDestinationTypePickupPoint);
        const supportsDestinationTypeAddress = useObserver(() => store.currentPlaceService.place!.supportsDestinationTypeAddress);

		useEffect(
			() =>
			{
				if (supportedDestinationTypes.length === 1)
					setDestinationType(supportedDestinationTypes[0]);
			},
			[setDestinationType, supportedDestinationTypes],
		);

        return useObserver(() => {
            if (!destinationAddressInitialized)
            {
                return <Loader/>;
            }

            return <div
                style={{
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {
                    supportedDestinationTypes.length > 1 &&
                    <div
                        style={{
                            marginTop: -12,
                            marginBottom: 20,
                        }}
                    >
                        <ToggleButtonGroup
                            value={destinationType}
                            onChange={(e, value) => setDestinationType(value ?? undefined)}
                            exclusive
                            style={{
                                display: 'flex',
                                justifyContent: 'stretch',
                                backgroundColor: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,0)' : undefined,
                            }}
                        >
                            {supportsDestinationTypePlace ? <ToggleButton value="PLACE" key={1} style={{
                                flex: '1 1 auto',
                                borderColor: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                color: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                pointerEvents: destinationType === 'PLACE' ? 'none' : undefined,
                            }}>
                                {localizer.translate('Client-Order-Eat-Here')}
                            </ToggleButton> : undefined}
                            {supportsDestinationTypePickupPoint ? <ToggleButton value="PICKUP_POINT" key={2} style={{
                                flex: '1 1 auto',
                                borderColor: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                color: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                pointerEvents: destinationType === 'PICKUP_POINT' ? 'none' : undefined,
                            }}>
                                {localizer.translate('Client-Order-Pick-Up')}
                            </ToggleButton> : undefined}
                            {supportsDestinationTypeAddress ? <ToggleButton value="ADDRESS" key={3} style={{
                                flex: '1 1 auto',
                                borderColor: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                color: (store.showValidation && store.destinationTypeValidation.length > 0) ? 'rgba(244,67,54,1)' : undefined,
                                pointerEvents: destinationType === 'ADDRESS' ? 'none' : undefined,
                            }}>
                                {localizer.translate('Client-Order-Delivery')}
                            </ToggleButton> : undefined}
                        </ToggleButtonGroup>
                        {
                            store.showValidation && store.destinationTypeValidation.length > 0 &&
                            <Typography
                                style={{
                                    marginTop: 4,
                                    fontSize: '0.75rem',
                                }}
                                color="error"
                            >
                                {store.destinationTypeValidation[0]}
                            </Typography>
                        }
                    </div>
                }
                {
                    (store.supportsBuzzerNumber || store.supportsFirstName || store.supportsLastName || store.supportsCompanyName || store.supportsAddress || store.supportsPhoneNumber || store.supportsEmail) &&
                    <div>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                        >
                            {store.justificationText}
                        </Typography>
                    </div>
                }
                {
                    store.supportsBuzzerNumber &&
                    <TextField
                        fullWidth
                        inputRef={store.buzzerNumberField}
                        variant="outlined"
                        error={store.showValidation && store.buzzerNumberValidation.length > 0}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        label={store.buzzerNumberLabel}
                        value={store.buzzerNumberFieldValue}
                        InputProps={{
                            type: 'tel'
                        }}
                        margin="dense"
                        onChange={event => store.setBuzzerNumber(event.target.value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        helperText={
                            store.showValidation && (store.buzzerNumberErrorMessage !== undefined)
                                ? <span
                                    style={{marginLeft: -12}}
                                >
                                    {store.buzzerNumberErrorMessage}
                                </span>
                                : null
                        }
                        type={'tel'}
                    />
                }
                {
                    store.supportsFirstName &&
                    <TextField
                        fullWidth
                        inputRef={store.firstNameField}
                        variant="outlined"
                        error={store.showValidation && store.firstNameValidation.length > 0}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        label={store.firstNameLabel}
                        value={store.firstNameFieldValue}
                        InputProps={{
                            type: store.isClientNameNumeric
                                ?
                                'tel'
                                :
                                undefined
                        }}
                        margin="dense"
                        onChange={event => store.setFirstName(event.target.value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        helperText={
                            store.showValidation && (formatValidationMessagesToText(store.firstNameValidation) !== undefined)
                                ? <span
                                    style={{marginLeft: -12}}
                                >
                                    {store.showValidation ? formatValidationMessagesToText(store.firstNameValidation) : null}
                                </span>
                                : null
                        }
                        type={store.isClientNameNumeric ? 'tel' : undefined}
                    />
                }
                {
                    store.supportsLastName &&
                    <TextField
                        fullWidth
                        inputRef={store.lastNameField}
                        variant="outlined"
                        error={store.showValidation && store.lastNameValidation.length > 0}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        label={store.lastNameLabel}
                        value={store.lastNameFieldValue}
                        margin="dense"
                        onChange={event => store.setLastName(event.target.value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        helperText={
                            store.showValidation && (store.lastNameErrorMessage !== undefined)
                                ? <span
                                    style={{marginLeft: -12}}
                                >
                        {store.lastNameErrorMessage}
                    </span>
                                : null
                        }
                    />
                }
                {
                    store.supportsCompanyName &&
                    <TextField
                        fullWidth
                        inputRef={store.companyNameField}
                        variant="outlined"
                        error={store.showValidation && store.companyNameValidation.length > 0}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        label={store.companyNameLabel}
                        value={store.companyNameFieldValue}
                        margin="dense"
                        onChange={event => store.setCompanyName(event.target.value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        helperText={
                            store.showValidation && (store.companyNameErrorMessage !== undefined)
                                ? <span
                                    style={{marginLeft: -12}}
                                >
                            {store.companyNameErrorMessage}
                        </span>
                                : null
                        }
                    />
                }
                {
                    store.supportsAddress &&
                    <OrderDestinationAddressForm
                        addressRestrictions={place.addressRestrictions}
                        value={destinationAddress!}
                        onChange={setDestinationAddress}
                        onValidityUpdate={setAddressValidation}
                        showValidation={store.showValidation}
                    />
                }
                {
                    store.supportsPhoneNumber &&
                    <PhoneNumberInput
                        fullWidth
                        inputRef={store.phoneNumberField}
                        error={store.showValidation && store.phoneNumberValidation.length > 0}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        label={store.phoneNumberLabel}
                        value={store.phoneNumberFieldValue}
                        margin="dense"
                        onChange={phoneNumber => store.setPhoneNumber(phoneNumber)}
                        defaultCountry={businessCountry}
                        rootStyle={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        helperText={
                            store.showValidation && (store.phoneNumberErrorMessage !== undefined)
                                ?
                                store.showValidation ? store.phoneNumberErrorMessage : undefined
                                :
                                undefined
                        }
                    />
                }
                {
                    store.supportsEmail &&
                    <EmailForm
                        fullWidth
                        inputRef={store.emailField}
                        showValidation={store.showValidation}
                        required={store.requiresEmail}
                        onBlur={event => store.onBlur(event.relatedTarget as HTMLElement)}
                        onKeyPress={store.listenToEnterKey}
                        value={store.emailValue}
                        margin="dense"
                        onChange={value => store.setEmail(value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    />
                }
            </div>;
        });
    }