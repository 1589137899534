import { Box, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOption } from '../../option/FeatureConfigurationOption';
import { FeatureConfigurationOptionGroupSize } from '../FeatureConfigurationOptionGroupSize';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',

		'& > *': {
			paddingBottom: 9,
			paddingTop: 9,
		},
	},
});

interface FeatureConfigurationOptionGroupItemsProps
{
	size: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroupItems: FC<FeatureConfigurationOptionGroupItemsProps> =
	({
		size,
		store,
	}) =>
	{
		const variants = useObserver(
			() =>
				store.featureAssignment.productFeature.variants,
		);

		const classes = useStyles();

		return <Box
			className={classes.container}
		>
			{
				variants.map(
					variant =>
						<FeatureConfigurationOption
							key={variant.id}
							size={size}
							store={store}
							variant={variant}
						/>,
				)
			}
		</Box>;
	};
