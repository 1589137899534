import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../FeatureConfiguration/FeatureConfigurationStore';
import { FeatureConfigurationOptionGroupSize } from './FeatureConfigurationOptionGroupSize';
import { FeatureConfigurationOptionGroupHeader } from './header/FeatureConfigurationOptionGroupHeader';
import { FeatureConfigurationOptionGroupItems } from './items/FeatureConfigurationOptionGroupItems';
import { FeatureConfigurationOptionGroupValidationMessages } from './validation_messages/FeatureConfigurationOptionGroupValidationMessages';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 5,
		transition: theme.transitions.create('background-color'),

		'& > :not(:first-child)': {
			marginTop: 11,
		},
	},
	containerError: {
		backgroundColor: '#FF000011',
	},
}));

interface FeatureConfigurationOptionGroupProps
{
	hideHeader?: boolean;
	showValidation?: boolean;
	size?: FeatureConfigurationOptionGroupSize;
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroup: FC<FeatureConfigurationOptionGroupProps> =
	({
		hideHeader,
		showValidation: showParentValidation,
		size = 'Small',
		store,
	}) =>
	{
		const classes = useStyles();

		const validationErrors = useObserver(() => store.validationErrors);
		const showFeatureConfigurationValidation = useObserver(() => store.showValidation);

		const showValidation = showParentValidation || showFeatureConfigurationValidation;

		return <Box
			className={
				clsx(
					classes.container,
					{
						[classes.containerError]: showValidation && validationErrors.length > 0,
					},
				)
			}
		>
			{
				!hideHeader &&
				<FeatureConfigurationOptionGroupHeader
					store={store}
				/>
			}
			<FeatureConfigurationOptionGroupItems
				size={size}
				store={store}
			/>
			<FeatureConfigurationOptionGroupValidationMessages
				showValidation={showValidation}
				store={store}
			/>
		</Box>;
	};
