import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

interface HistoricalCommentItemProps
{
	comment: string;
}

export const HistoricalCommentItem: FC<HistoricalCommentItemProps> =
	({
		comment,
	}) =>
	{
		const localizer = useLocalizer();

		const description = useMemo(
			() =>
				`${localizer.translate('Order-Comment')}: ${comment}`,
			[comment, localizer],
		);

		return <GenericHistoricalItem
			description={description}
		/>;
	};
