import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../../../FeatureConfiguration/FeatureConfigurationStore';

const useStyles = makeStyles({
	text: {
		color: 'rgb(0, 0, 0)',
		fontSize: 16,
		fontWeight: 700,
		lineHeight: '19px',
	},
});

interface FeatureConfigurationOptionGroupTitleProps
{
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroupTitle: FC<FeatureConfigurationOptionGroupTitleProps> =
	({
		store,
	}) =>
	{
		const constraintMessages = useObserver(
			() =>
				store.constraintMessages,
		);

		const name = useObserver(
			() =>
				store.featureAssignment.productFeature.name,
		);

		const classes = useStyles();

		return <Typography
			className={classes.text}
		>
			{name}
			{
				constraintMessages.length > 0 &&
				` (${constraintMessages.map(msg => msg.slice(0, 1).toLowerCase() + msg.slice(1)).join(' - ')})`
			}
		</Typography>;
	};
