import { makeAutoObservable } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import { LanguageEntryTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { equalLanguageEntryTranslations, LanguageEntryTranslations } from '../Other/LanguageEntryTranslations';

export class ProductRecommendation
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly uuid: string;

	recommendedProductId?: number;
	recommendationText?: LanguageEntryTranslations | string;
	orderNumber: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		uuid: string | undefined = uuidV4(),
		orderNumber: number,
		recommendedProductId: number,
		recommendationText?: LanguageEntryTranslations | string,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.uuid = uuid;
		this.orderNumber = orderNumber;
		this.recommendedProductId = recommendedProductId;
		this.recommendationText = recommendationText;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/
}

// Makes sure the order numbers are sequentially ascending and start from 0
export function normalizeProductRecommendations(recommendations: ProductRecommendation[]): ProductRecommendation[]
{
	const orderedRecommendations = recommendations
		.slice()
		.sort((a, b) => a.orderNumber - b.orderNumber);

	orderedRecommendations.forEach((recommendation, idx) => recommendation.orderNumber = idx);

	return orderedRecommendations;
}

export function equalProductRecommendations(a: ProductRecommendation | null | undefined, b: ProductRecommendation | null | undefined)
{
	if (a === null || a === undefined || b === null || b === undefined)
		return a === b;
	else
	{
		const recommendationTextsEqual = (!isString(a.recommendationText) && !isString(b.recommendationText) && equalLanguageEntryTranslations(a.recommendationText, b.recommendationText))
			|| a.recommendationText === b.recommendationText;
		return a.uuid === b.uuid
			&& a.recommendedProductId === b.recommendedProductId
			&& recommendationTextsEqual
			&& a.orderNumber === b.orderNumber
	}
}

function isString(a: any): a is string
{
	return typeof a === 'string';
}

export const ProductRecommendationProfile = SerializationProfile
	.create(ProductRecommendation)
	.transform('recommendationText', LanguageEntryTransformer);