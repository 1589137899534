import { Box, Collapse, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { BusinessContextRef } from '../../BusinessContext';
import { FeatureConfiguration } from './FeatureConfiguration/FeatureConfiguration';
import { FeatureConfigurationOptionGroup } from './option_group/FeatureConfigurationOptionGroup';
import { ProductConfigurationStore } from './ProductConfigurationStore';
import { RecommendedProductConfiguration } from './RecommendedProductConfiguration/RecommendedProductConfiguration';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 11,
		paddingTop: 19,
		paddingBottom: 19,
	},
});

interface ProductConfigurationProps
{
	store: ProductConfigurationStore;
}

export const ProductConfiguration: FC<ProductConfigurationProps> =
	({
		store,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const product = useObserver(() => store.product);
		const showValidation = useObserver(() => store.showValidation);
		const visibleConfigurationStores = useObserver(() => store.visibleConfigurationStores);

		const visibleProductRecommendations = useObserver(
			() =>
			{
				if (store.recommendProducts)
				{
					const productRecommendationLists = businessStore.hasProductRecommendations(product.id, 'AT_PRODUCT_OPTIONS')
						? businessStore.getProductRecommendationLists(product.id).filter(list => list.moment === 'AT_PRODUCT_OPTIONS')
						: [];

					return productRecommendationLists.filter(
						productRecommendationList =>
							productRecommendationList.productRecommendations.some(
								productRecommendation => businessStore.productById.has(
									productRecommendation.recommendedProductId,
								),
							),
					);
				}
				else
				{
					return [];
				}
			},
		);

		const classes = useStyles();

		if (visibleConfigurationStores.length > 0 || visibleProductRecommendations.length > 0)
		{
			return <TransitionGroup
				componen1t={Box}
				className={classes.container}
			>
				{
					visibleConfigurationStores.map(
						featureConfigurationStore =>
							<Collapse
								key={featureConfigurationStore.featureAssignment.id}
							>
								{
									featureConfigurationStore.hasAnyImage
										// TODO: For now fall back to the old components. Option group components with any image should be properly designed and implemented.
										? <FeatureConfiguration
											productConfigurationStore={store}
											store={featureConfigurationStore}
										/>
										: <FeatureConfigurationOptionGroup
											showValidation={showValidation}
											store={featureConfigurationStore}
										/>
								}
							</Collapse>,
					)
				}
				{
					visibleProductRecommendations.map(
						productRecommendationList =>
							<Collapse
								key={productRecommendationList.uuid}
							>
								<RecommendedProductConfiguration
									productConfigurationStore={store}
									productConfigurationList={productRecommendationList}
								/>
							</Collapse>,
					)
				}
			</TransitionGroup>;
		}
		else
		{
			return null;
		}
	};
