import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Place, PlaceProfile } from './Place';

export class PlaceSession
{
    id: number;
    externalId?: string;
    place: Place;
    startDate: number;
    endDate?: number;
    numberOfCovers: number;
    menuCardIds: number[];

    constructor(
        place: Place,
        numberOfCovers: number,
        menuCardIds?: number[]
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.place = place;
        this.numberOfCovers = numberOfCovers;
        this.menuCardIds = menuCardIds;
    }

    get startTime(): string
    {
        let date = new Date(this.startDate);
        let minutes = date.getMinutes();

        if (minutes > 9)
        {
            return `${date.getHours()}:${minutes}`;
        }
        else
        {

            return `${date.getHours()}:0${minutes}`;
        }
    }
}

export const PlaceSessionProfile =
    SerializationProfile.create(PlaceSession)
        .profile('place', PlaceProfile)
