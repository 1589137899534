import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../../../FeatureConfiguration/FeatureConfigurationStore';

const useStyles = makeStyles({
	text: {
		color: 'rgb(0, 0, 0, 0.48)',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px',
	},
});

interface FeatureConfigurationOptionGroupDescriptionProps
{
	store: FeatureConfigurationStore;
}

export const FeatureConfigurationOptionGroupDescription: FC<FeatureConfigurationOptionGroupDescriptionProps> =
	({
		store,
	}) =>
	{
		const description = useObserver(
			() =>
				store.featureAssignment.productFeature.description,
		);

		const classes = useStyles();

		if (description === undefined)
		{
			return null;
		}
		else
		{
			return <Typography
				className={classes.text}
			>
				{description}
			</Typography>;
		}
	};
