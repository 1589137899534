import { Avatar, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { MoneyDescriptor } from '../../../../../../Api/Util/money/MoneyDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';
import PriceTag from '../../../../../UI/PriceTag/PriceTag';

const ThumbnailSize = 38;

const useStyles = makeStyles({
	avatar: {
		width: ThumbnailSize,
		height: ThumbnailSize,
	},
	text: {
		marginBottom: 0,
		marginTop: 0,
	},
});

interface GenericHistoricalItemContentProps
{
	description: string;
	descriptionSecondary?: string;
	imageUrl?: string;
	quantity?: number;
	price?: MoneyDescriptor;
	translateZeroPriceAmount?: boolean;
}

export const GenericHistoricalItemContent: FC<GenericHistoricalItemContentProps> =
	({
		description,
		descriptionSecondary,
		imageUrl,
		quantity,
		price,
		translateZeroPriceAmount,
	}) =>
	{
		const localizer = useLocalizer();

		const priceAmount = useMemo(
			() =>
				price === undefined
					? undefined
					: new Decimal(price.amount),
			[price],
		);

		const classes = useStyles();

		return <>
			{
				imageUrl !== undefined &&
				<ListItemAvatar>
					<Avatar
						alt={description}
						src={getFileUrl(`/image/${ThumbnailSize}/${ThumbnailSize}/Cover/High${imageUrl}`)}
						className={classes.avatar}
					/>
				</ListItemAvatar>
			}
			<ListItemText
				className={classes.text}
				primary={`${quantity === undefined ? '' : `${quantity}× `}${description}`}
				secondary={descriptionSecondary}
			/>
			{
				priceAmount !== undefined &&
				<PriceTag
					color="textSecondary"
					component="span"
					currencyCode={price.currency}
					localizer={localizer}
					price={priceAmount}
					showFreeWhenZero={translateZeroPriceAmount}
				/>
			}
		</>;
	};
