import { QueryParameters } from '../../../../Constants/QueryParameters';
import { getUrlParameterByName } from '../../../../Util/Url/getUrlParameterByName';

export function getSkipEntranceStoriesFromUrl(): boolean
{
	const value = getUrlParameterByName(QueryParameters.SkipEntranceStories);

	return value === '1' ||
		value === 'true';
}
