import { useObserver } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { fetchAny } from '../../../../../Util/Api';
import { AsyncResult, useAsyncResult } from '../../../../../Util/async/useAsyncResult';
import { useCurrentOrderService } from '../../BusinessContext';
import { TimeSlot, TimeSlotDto, toTimeSlot } from '../TimeSlot';

export function useOrderingTimeSlots(day: string | undefined): AsyncResult<TimeSlot[]>
{
	const currentOrderService = useCurrentOrderService(true);

	const violatedReports = useObserver(() => currentOrderService.violatedReports);

	const timeZone = useMemo(
		() =>
			Intl
				.DateTimeFormat()
				.resolvedOptions()
				.timeZone,
		[],
	);

	const result = useAsyncResult(
		async () =>
		{
			if (day !== undefined && day.trim().length > 0)
			{
				const response = await fetchAny<TimeSlotDto[]>(
					'client/timeSlots',
					{
						day,
						timeZone,
					},
				);
				return response
					.map(toTimeSlot);
			}
		},
		[day, timeZone],
	);

	const {reload: reloadTimeSlots} = result;

	useEffect(
		() =>
		{
			const hasViolatingScheduledOrderLimitReports = (violatedReports ?? []).some(
				report =>
					report.type === 'ScheduledOrderLimit',
			);

			if (hasViolatingScheduledOrderLimitReports)
				reloadTimeSlots();
		},
		[reloadTimeSlots, violatedReports],
	);

	return result;
}