import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ApiHeaders } from '../../../Api/v3/ApiHeaders';
import { QueryParameters } from '../../../Constants/QueryParameters';
import { StorageVars } from '../../../Constants/StorageConstants';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';
import { useAsyncEffect } from '../../../Util/async/useAsyncEffect';
import { toQueryParams } from '../../../Util/toQueryParams';
import { DefaultErrorPage } from '../../Page/Exception/DefaultErrorPage';
import { QueryParameterContext } from '../../query-parameter-intercepter/QueryParameterContext';
import { useStorage } from '../../Root/StorageContextProvider';
import { AuthenticationContext } from '../AuthenticationContext';
import { AuthenticationResultV3 } from '../AuthenticationResult';
import { getAccountFromStorage } from '../getAccountFromStorage';
import { RefreshTokenPage } from '../RefreshTokenPage';
import { setAuthorizationHeaders } from '../setAuthorizationHeaders';
import { updateSentryScope } from '../updateSentryScope';
import { authorize } from './authorize';
import { getRefreshTimeoutPeriod } from './getRefreshTimeoutPeriod';
import { loginLegacy } from './loginLegacy';
import { refreshAccessToken } from './refreshAccessToken';
import { resolveAuthentication } from './resolveAuthentication';

export const AuthenticationContextProviderV3: FC =
	({
		children,
	}) =>
	{
		const queryParameters = useContext(QueryParameterContext);
		const storage = useStorage(true);
		const [didRedirect, setDidRedirect] = useState(false);
		const [didInitialize, setDidInitialize] = useState(false);
		const [authenticationResult, setAuthenticationResult] = useState<AuthenticationResultV3 | undefined>();
		const [refreshTimeoutId, setRefreshTimeoutId] = useState<NodeJS.Timeout | undefined>();
		const [initializationError, setInitializationError] = useState<Error>();
		const [refreshTokenHasError, setRefreshTokenHasError] = useState(false);
		const headers = useRef({});

		const code = useMemo(
			() =>
				queryParameters.get(QueryParameters.OAuth2Code) ?? undefined,
			[queryParameters],
		);

		const error = useMemo(
			() =>
				window.location.pathname === '/auth'
					? queryParameters.get(QueryParameters.OAuth2Error) ?? undefined
					: undefined,
			[queryParameters],
		);

		const state = useMemo(
			() =>
				queryParameters.get(QueryParameters.OAuth2State) ?? undefined,
			[queryParameters],
		);

		const recover = useMemo(
			() =>
				queryParameters.get(QueryParameters.OAuth2RecoverMode) ?? undefined,
			[queryParameters],
		);

		const generateAuthenticationPromise = useCallback(
			async () =>
			{
				const account = authenticationResult === undefined
					? getAccountFromStorage(storage)
					: undefined;

				const aid = account === undefined
					? undefined
					: await loginLegacy(account);

				if (authenticationResult === undefined)
					await authorize(storage, false, aid, true);
			},
			[authenticationResult, storage],
		);

		useEffect(() =>
		{
			return () =>
				clearTimeout(refreshTimeoutId);
		}, [refreshTimeoutId]);

		const refresh = useCallback(
			async (result: AuthenticationResultV3) =>
			{
				const newResult = await refreshAccessToken(result.refreshToken);

				if (newResult !== undefined)
				{
					setRefreshTokenHasError(false);

					setAuthorizationHeaders(newResult);

					headers.current = {
						[ApiHeaders.authorization]: `Bearer ${result.accessToken}`,
					};

					const refreshTimeoutId = setTimeout(
						() =>
							refresh(newResult),
						getRefreshTimeoutPeriod(newResult),
					);

					setRefreshTimeoutId(refreshTimeoutId);
				}
				else
				{
					setRefreshTokenHasError(true);
				}
			},
			[],
		);

		useAsyncEffect(
			() =>
				({
					promise: authenticationResult === undefined && code !== undefined && state !== undefined
						? resolveAuthentication(storage, code, state, recover !== undefined)
							.then(
								result =>
								{
									updateSentryScope(result);
									setAuthorizationHeaders(result);

									headers.current = {
										[ApiHeaders.authorization]: `Bearer ${result.accessToken}`,
									};

									setAuthenticationResult(result);

									const refreshTimeoutId = setTimeout(
										() =>
											refresh(result),
										getRefreshTimeoutPeriod(result),
									);

									setRefreshTimeoutId(refreshTimeoutId);

									if (!didRedirect)
									{
										const returnUrl = storage.get(StorageVars.OidcStateUrl) ?? '/';
										window.history.replaceState(undefined, window.document.title, returnUrl);
										setDidRedirect(true);
									}
								},
							)
						: window.location.pathname === '/auth'
							? Promise.resolve()
							: generateAuthenticationPromise(),
					then:
						() =>
							setDidInitialize(true),
					catch:
						error =>
						{
							console.error(`${error}`);
							setInitializationError(error);
						},
				}),
			[authenticationResult, code, didRedirect, generateAuthenticationPromise, recover, refresh, state, storage],
		);

		const login = useCallback(
			async (
				partnerLogoUrl?: string,
			) =>
			{
				const account = getAccountFromStorage(storage);

				const aid = account === undefined
					? undefined
					: await loginLegacy(account);

				await authorize(storage, false, aid, false, partnerLogoUrl);
			},
			[storage],
		);

		const logout = useCallback(
			async (
				partnerLogoUrl?: string,
			) =>
			{
				const params: Record<string, string> = {
					postLogoutRedirectUrl: window.location.href,
				};

				if (partnerLogoUrl !== undefined)
					params.partnerLogoUrl = partnerLogoUrl;

				window.location.assign(
					getAuthUrl(`/logout?${toQueryParams(params)}`),
				);
			},
			[],
		);

		const contextValue = useMemo(
			() =>
				({
					authenticationResult,
					headers,
					didInitialize,
					initializationError,
					login,
					logout,
					registerAccount: () =>
					{
						throw new Error('V3 Authentication does not support account registration');
					},
					verifyAccount: () =>
					{
						throw new Error('V3 Authentication does not support account verification');
					},
				}),
			[authenticationResult, didInitialize, initializationError, login, logout],
		);

		if (refreshTokenHasError)
		{
			return <RefreshTokenPage />;
		}
		else if (didRedirect)
		{
			return <AuthenticationContext.Provider
				value={contextValue}
			>
				{children}
			</AuthenticationContext.Provider>;
		}
		else
		{
			if (error !== undefined || initializationError !== undefined)
			{
				return <DefaultErrorPage
					action={
						() =>
						{
							const redirectUri = storage.get(StorageVars.OidcStateUrl);
							window.location.replace(redirectUri ?? '/');
						}
					}
				/>;
			}
			else
			{
				return null;
			}
		}
	};
