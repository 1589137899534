import { BaseStore } from '@intentic/ts-foundation';
import { Decimal } from 'decimal.js';
import { action, computed, makeObservable, observable, ObservableMap } from 'mobx';
import { Product } from '../../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';
import { ProductConfigurationVariant } from '../../../../../Api/Product/ProductConfigurationVariant';
import { ProductFeatureAssignment } from '../../../../../Api/Product/ProductFeatureAssignment';
import { ProductFeatureVariant } from '../../../../../Api/Product/ProductFeatureVariant';
import { ProductRecommendationList } from '../../../../../Api/Product/ProductRecommendationList';
import { Bridge } from '../../../../../Bridge/Bridge';
import { IllegalArgumentException } from '../../../../../Util/Exception/IllegalArgumentException';
import { FeatureConfigurationStore } from './FeatureConfiguration/FeatureConfigurationStore';
import { ShoppingCartLine } from './ShoppingCartLine';

export class ProductConfigurationStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    bridge: Bridge;
    product: Product;
    productCurrencyCode: string;
    onClose: () => Promise<any>;
    public readonly recommendProducts: boolean;

    featureConfigurationStores: FeatureConfigurationStore[] = observable.array<FeatureConfigurationStore>();
    featureConfigurationStoreByAssignment = observable.map<ProductFeatureAssignment, FeatureConfigurationStore>();
    variantsByQuantity = observable.map<ProductFeatureVariant, number>();
    showValidation: boolean;
    private readonly additionalProducts = observable.map<ProductRecommendationList, ObservableMap<string, ShoppingCartLine>>();

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        product: Product,
        productCurrencyCode: string,
        onClose: () => Promise<any>,
        recommendProducts: boolean,
    )
    {
        super();

        makeObservable<ProductConfigurationStore, 'additionalShoppingCartLines'>(
            this,
            {
                bridge: observable,
                product: observable,
                productCurrencyCode: observable,
                onClose: observable,
                featureConfigurationStores: observable,
                featureConfigurationStoreByAssignment: observable,
                variantsByQuantity: observable,
                showValidation: observable,
                visibleConfigurationStores: computed,
                price: computed,
                productConfiguration: computed,
                isInStock: computed,
                validationErrors: computed,
                isValid: computed,
                additionalShoppingCartLines: computed,
                close: action.bound,
                selectVariant: action.bound,
                deselect: action.bound,
                addProductConfiguration: action.bound,
                removeProductConfiguration: action.bound,
                setShowValidation: action.bound,
            },
        );

        this.bridge = bridge;
        this.product = product;
        this.productCurrencyCode = productCurrencyCode;
        this.onClose = onClose;
        this.recommendProducts = recommendProducts;
        this.showValidation = false;

        this.product.presentFeatureAssignments.forEach(assignment => {
            const configurationStore = new FeatureConfigurationStore(
                this.bridge,
                this.product,
                assignment,
                this.productCurrencyCode,
                this.selectVariant,
                this.deselect,
            );

            this.featureConfigurationStores.push(configurationStore);
            this.featureConfigurationStoreByAssignment.set(assignment, configurationStore);
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get visibleConfigurationStores(): FeatureConfigurationStore[]
    {
        return this.featureConfigurationStores
            .filter(configurationStore =>
            {
                if (!configurationStore.featureAssignment.isConditional)
                {
                    return true;
                }
                else if (
                    configurationStore.featureAssignment.conditionalFeatureVariant === undefined
                    || configurationStore.featureAssignment.conditionalFeatureAssignment === undefined
                )
                {
                    return false;
                }
                else
                {
                    return this.featureConfigurationStoreByAssignment.has(configurationStore.featureAssignment.conditionalFeatureAssignment)
                        && this.featureConfigurationStoreByAssignment.get(configurationStore.featureAssignment.conditionalFeatureAssignment)!
                            .selectedVariants.has(configurationStore.featureAssignment.conditionalFeatureVariant);
                }
            });
    }

    get price(): Decimal
    {
        let price = this.productConfiguration.price;
        for (const shoppingCartLine of this.additionalShoppingCartLines)
        {
            price = price.add(shoppingCartLine.price);
        }
        return price;
    }

    get productConfiguration(): ProductConfiguration
    {
        const productConfigurationVariants: ProductConfigurationVariant[] = [];

        this.visibleConfigurationStores
            .forEach(
                configurationStore =>
                    configurationStore.selectedVariants.forEach(
                        (isSelected: boolean, selectedVariant: ProductFeatureVariant) =>
                            productConfigurationVariants.push({
                                variant: selectedVariant,
                                assignment: configurationStore.featureAssignment
                            })));

        return new ProductConfiguration(
            this.product,
            productConfigurationVariants
        );
    }

    get isInStock(): boolean
    {
        for (let featureConfigurationStore of this.featureConfigurationStores)
        {
            if (!featureConfigurationStore.isInStock)
            {
                return false;
            }
        }
        return true;
    }

    public get validationErrors(): string[]
    {
        const errors: string[] = [];
        this.visibleConfigurationStores
            .forEach(configurationStore => configurationStore.validationErrors
                .forEach(error => errors.push(error))
            );
        return errors;
    }

    public get isValid(): boolean
    {
        return this.validationErrors.length === 0;
    }

    private get additionalShoppingCartLines()
    {
        return Array.from(this.additionalProducts.values())
            .flatMap(aMap => Array.from(aMap.values()));
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    close()
    {
        return this.onClose();
    }

    public requestValidShoppingCartLines(amount: number = 1)
    {
        const productConfiguration = this.requestValidProductConfiguration();
        if (productConfiguration !== undefined)
        {
            return [
                new ShoppingCartLine(productConfiguration, amount),
                ...this.additionalShoppingCartLines
            ];
        }
    }

    /**
     * Side effect: When called, this store will enable the showing of the validation errors related to {@link ProductConfiguration}
     * if there are any.
     * @return The {@link ProductConfiguration} when a valid one can be offered by this {@link ProductConfigurationStore}, and
     * `undefined` when the current input is invalid.
     */
    public requestValidProductConfiguration(): ProductConfiguration | undefined
    {
        this.setShowValidation(true);
        return this.isValid
            ?
            this.productConfiguration
            :
            undefined;
    }

    selectVariant(variant: ProductFeatureVariant)
    {
        if (!this.variantsByQuantity.has(variant))
        {
            this.variantsByQuantity.set(variant, 0);
        }

        this.variantsByQuantity.set(variant, this.variantsByQuantity.get(variant)! + 1);
    }

    deselect(variant: ProductFeatureVariant)
    {
        if (this.variantsByQuantity.has(variant))
        {
            let quantity = this.variantsByQuantity.get(variant)! - 1;

            if (quantity <= 0)
            {
                this.variantsByQuantity.delete(variant);
            }
            else
            {
                this.variantsByQuantity.set(variant, quantity);
            }
        }
    }

    public getProductConfigurations(
        productRecommendationList: ProductRecommendationList,
    ): ShoppingCartLine[]
    {
        if (this.additionalProducts.has(productRecommendationList))
            return Array.from(this.additionalProducts.get(productRecommendationList)!.values());
        else
            return [];
    }

    public addProductConfiguration(
        productRecommendationList: ProductRecommendationList,
        productConfiguration: ProductConfiguration,
    )
    {
        if (!this.additionalProducts.has(productRecommendationList))
            this.additionalProducts.set(productRecommendationList, observable.map<string, ShoppingCartLine>());

        const relevantRecommendedProductMap = this.additionalProducts.get(productRecommendationList)!;
        if (relevantRecommendedProductMap.has(productConfiguration.id))
            relevantRecommendedProductMap
                .get(productConfiguration.id)!
                .incrementQuantity();
        else
            relevantRecommendedProductMap
                .set(
                    productConfiguration.id,
                    new ShoppingCartLine(
                        productConfiguration,
                        1
                    )
                );

    }

    public removeProductConfiguration(
        productConfiguration: ProductConfiguration,
    )
    {
        const existing = (() => {
            for (const productRecommendationList of Array.from(this.additionalProducts.keys()))
            {
                const productConfigurations = this.additionalProducts.get(productRecommendationList);
                if (productConfigurations !== undefined)
                    for (const key of Array.from(productConfigurations.keys()))
                    {
                        const shoppingCartLine = productConfigurations.get(key)!;
                        const existingProductConfiguration = shoppingCartLine.productConfiguration;
                        if (existingProductConfiguration === productConfiguration)
                            return {
                                existingProductConfiguration,
                                productRecommendationList,
                            };
                    }
            }
            return undefined;
        })();

        if (!existing)
            throw new IllegalArgumentException('ProductConfiguration not found');

        const {
            productRecommendationList,
            existingProductConfiguration
        } = existing;

        const productConfigurationsMapToRemoveFrom = this.additionalProducts
            .get(productRecommendationList)!;

        productConfigurationsMapToRemoveFrom
            .delete(existingProductConfiguration.id);

        if (productConfigurationsMapToRemoveFrom.size === 0)
            this.additionalProducts.delete(productRecommendationList);
    }

    public setShowValidation(showValidation: boolean): void
    {
        this.showValidation = showValidation;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
