import { makeStyles } from '@material-ui/core';
import { TextButtonElement } from '../../../../../../../Api/v3/model/story_post/content/element/TextButtonElement';
import { getLinearGradientCss } from '../../../api/getLinearGradientCss';
import { getSolidColorCss } from '../../../api/getSolidColorCss';

const PaddingXFactor = 0.4;
const PaddingYFactor = 0.2;
const BorderWidthFactor = 0.1;
const BorderRadiusFactor = 0.5;

export function useTextButtonElementClassName(
	element: Pick<TextButtonElement, 'backgroundColor' | 'borderColor' | 'textSize'>,
	postHeight: number,
): string
{
	return makeStyles({
		element: {
			minWidth: 0,
			borderRadius: `${element.textSize * BorderRadiusFactor * postHeight}px`,
			...element.borderColor === undefined
				? {
					paddingTop: `${element.textSize * PaddingYFactor * postHeight}px`,
					paddingRight: `${element.textSize * PaddingXFactor * postHeight}px`,
					paddingBottom: `${element.textSize * PaddingYFactor * postHeight}px`,
					paddingLeft: `${element.textSize * PaddingXFactor * postHeight}px`,
				}
				: {},
			...element.borderColor?.type === 'Solid'
				? {
					paddingTop: `${element.textSize * (PaddingYFactor - BorderWidthFactor) * postHeight}px`,
					paddingRight: `${element.textSize * (PaddingXFactor - BorderWidthFactor) * postHeight}px`,
					paddingBottom: `${element.textSize * (PaddingYFactor - BorderWidthFactor) * postHeight}px`,
					paddingLeft: `${element.textSize * (PaddingXFactor - BorderWidthFactor) * postHeight}px`,
					borderWidth: `${element.textSize * BorderWidthFactor * postHeight}px`,
					borderStyle: 'solid',
					borderColor: getSolidColorCss(element.borderColor),
					overflow: 'hidden',
					'& > .MuiTouchRipple-root ': {
						inset: `-${element.textSize * BorderWidthFactor * postHeight}px`,
					},
				}
				: {},
			...element.borderColor?.type === 'LinearGradient'
				? {
					paddingTop: `${element.textSize * PaddingYFactor * postHeight}px`,
					paddingRight: `${element.textSize * PaddingXFactor * postHeight}px`,
					paddingBottom: `${element.textSize * PaddingYFactor * postHeight}px`,
					paddingLeft: `${element.textSize * PaddingXFactor * postHeight}px`,
					'&:before': {
						content: '""',
						position: 'absolute',
						inset: 0,
						padding: `${element.textSize * BorderWidthFactor * postHeight}px`,
						background: getLinearGradientCss(element.borderColor),
						borderRadius: 'inherit',
						WebkitMask: 'linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)',
						WebkitMaskComposite: 'xor',
						MaskComposite: 'exclude',
						pointerEvents: 'none',
					},
				}
				: {},
			...element.backgroundColor === undefined
				? {
					backgroundColor: 'transparent',
				}
				: {},
			...element.backgroundColor?.type === 'Solid'
				? {
					backgroundColor: getSolidColorCss(element.backgroundColor),
				}
				: {},
			...element.backgroundColor?.type === 'LinearGradient'
				? {
					background: getLinearGradientCss(element.backgroundColor),
					transformOrigin: '50% 50%',
				}
				: {},
		},
	})().element;
}
