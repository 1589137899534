import { Action } from '../../../../../Api/v3/model/story_post/content/action/Action';
import { ActionContext } from './ActionContext';
import { performAddProductToCartAction } from './performAddProductToCartAction';
import { performCloseAction } from './performCloseAction';
import { performOpenLinkAction } from './performOpenLinkAction';
import { performOpenProductAction } from './performOpenProductAction';
import { performOpenProductCategoryAction } from './performOpenProductCategoryAction';

export async function performAction(
	action: Action,
	context: ActionContext,
): Promise<void>
{
	switch (action.type)
	{
		case 'AddProductToCart':
			return performAddProductToCartAction(
				action,
				context,
			);

		case 'OpenLink':
			return performOpenLinkAction(
				action,
				context,
			);

		case 'OpenProduct':
			return performOpenProductAction(
				action,
				context,
			);

		case 'OpenProductCategory':
			return performOpenProductCategoryAction(
				action,
				context,
			);

		case 'Close':
			return performCloseAction(context);
	}
}
