import { Locale as DateFnsLocale } from 'date-fns';
import { action, makeObservable } from 'mobx';
import { QueryParameters } from '../../Constants/QueryParameters';
import { getUrlParameterByName } from '../../Util/Url/getUrlParameterByName';
import { Locale } from './Locale';
import { Localizer } from './Localizer';

export class WebLocalizer extends Localizer
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();
        makeObservable(this, {
            setLocale: action.bound,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    setLocale(locale: Locale): void
    {
        this.locale = locale;
    }

    // ------------------------ Public logic ------------------------

    findLocaleId(): string | undefined
    {
        return getUrlParameterByName(QueryParameters.Locale)
            || (window.navigator as any).userLanguage
            || window.navigator.language;
    }

    async resolveLocale(localeId: string): Promise<Locale>
    {
        const split = localeId.split('-');
        const languageCode = split[0].toLowerCase();
        const countryCode = split.length > 1 ? split[1].toUpperCase() : undefined;

        return new Locale(
            localeId,
            countryCode,
            languageCode,
            await this.resolveDateFnsLocale(localeId),
        );
    }

    async resolveDateFnsLocale(locale: string): Promise<DateFnsLocale>
    {
        return import(`date-fns/locale/${locale}/index.js`)
            .catch(() => import(`date-fns/locale/${locale.split('-')[0]}/index.js`))
            .catch(() => import('date-fns/locale/en-US/index.js'))
            .then(({default: locale}) => locale);
    }

    // ----------------------- Private logic ------------------------
}
