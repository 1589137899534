import AdyenCheckout from '@adyen/adyen-web';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import Core from '@adyen/adyen-web/dist/types/core';
import { PaymentMethodsConfiguration } from '@adyen/adyen-web/dist/types/core/types';
import { AdyenCheckoutError } from '../../../../../../lib/adyen/AdyenCheckoutError';
import { AdyenCheckoutState } from '../../../../../../lib/adyen/AdyenCheckoutState';
import { AdyenCheckoutEnvironment } from '../AdyenCheckoutEnvironment';

const DefaultPaymentMethodsConfiguration: PaymentMethodsConfiguration = {
	ideal: {
		showImage: false,
	},
};

export function constructAdyenCheckoutClient(
	environment: AdyenCheckoutEnvironment,
	locale: string,
	onAdditionalDetails: (state: AdyenCheckoutState, component: UIElement<any>) => void,
	onChange?: (state: AdyenCheckoutState) => void,
	onError?: (error: AdyenCheckoutError) => void,
	onSubmit?: (state: AdyenCheckoutState, component: UIElement<any>) => void,
	showPayButton?: boolean,
	paymentMethodsConfiguration?: PaymentMethodsConfiguration,
): Promise<Core>
{
	return AdyenCheckout({
		clientKey: environment === 'LIVE'
			? process.env.REACT_APP_ADYEN_CHECKOUT_LIVE_CLIENT_KEY
			: process.env.REACT_APP_ADYEN_CHECKOUT_TEST_CLIENT_KEY,
		environment,
		locale,
		onAdditionalDetails,
		onChange,
		onError,
		onSubmit,
		paymentMethodsConfiguration: {
			...DefaultPaymentMethodsConfiguration,
			...paymentMethodsConfiguration,
		},
		showPayButton,
	});
}
