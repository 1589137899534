import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { PlaceSession, PlaceSessionProfile } from '../../../../../Api/Business/PlaceSession';
import { PlaceSessionEndDateMutationEvent } from '../../../../../lib/event/place_session/PlaceSessionEndDateMutationEvent';
import { PlaceSessionNumberOfCoversMutationEvent } from '../../../../../lib/event/place_session/PlaceSessionNumberOfCoversMutationEvent';
import { PlaceSessionStartDateMutationEvent } from '../../../../../lib/event/place_session/PlaceSessionStartDateMutationEvent';
import { fetch } from '../../../../../Util/Api';
import { constructEntityWithIdRecordReducer } from '../../../../../Util/reducer/constructEntityWithIdRecordReducer';
import { useWebSocketQueryRxjs } from '../../../Business/useWebSocketQueryRxjs';
import { ManagementQuery } from '../../../Business/WebSocketService';
import { ManagerView } from '../../ManagerView';
import { useManagerMenuCardsContext } from '../menu-card/ManagerMenuCardsContext';
import { useManagerPlacesContext } from '../place/ManagerPlacesContext';
import { getPlaceSessions } from './getPlaceSessions';
import { postSavePlaceSession } from './postSavePlaceSession';

interface Context
{
	getPlaceSessionByPlaceId: (placeId: number) => PlaceSession | undefined
	savePlaceSession: (placeSession: PlaceSession, endSession: boolean) => Promise<PlaceSession>
}

const ContextRef = createContext<Context>(undefined as never);

interface ManagerPlaceSessionsContextProviderProps
{
	businessId: number
	view: ManagerView
}

export const ManagerPlaceSessionsContextProvider: FC<ManagerPlaceSessionsContextProviderProps> =
	(
		{
			businessId,
			children,
			view,
		},
	) =>
	{
		const {getMenuCard, menuCards} = useManagerMenuCardsContext();
		const {getPlaceById} = useManagerPlacesContext();

		const [placeSessionsById, placeSessionDispatch] = useReducer(constructEntityWithIdRecordReducer<PlaceSession>(), undefined);

		const [didInitialize, setDidInitialize] = useState<number>();

		const placeSessionIdByPlaceId = useMemo(() => {
			if (placeSessionsById === undefined)
				return undefined;
			else
				return Object
					.values(placeSessionsById)
					.reduce<Record<number, number>>((result, placeSession) => {
						result[placeSession.place.id] = placeSession.id;

						return result;
					}, {});
		}, [placeSessionsById]);

		const viewRequiresPlaceSessions = useMemo(() => view === 'places', [view]);

		useEffect(() => {
			return () => placeSessionDispatch({
				action: 'unload',
			});
		}, [businessId]);

		useEffect(() => {
			if (didInitialize !== businessId && menuCards !== undefined && viewRequiresPlaceSessions)
			{
				getPlaceSessions({business_id: businessId})
					.then(response => {
						placeSessionDispatch({
							action: 'replace',
							objects: response,
						});

						setDidInitialize(businessId);
					});
			}
		}, [businessId, didInitialize, getMenuCard, menuCards, viewRequiresPlaceSessions]);

		const getPlaceSessionByPlaceId = useCallback((placeId: number) => {
			const foundPlaceSessionId = placeSessionIdByPlaceId?.[placeId];

			if (foundPlaceSessionId)
				return placeSessionsById?.[foundPlaceSessionId];
		}, [placeSessionIdByPlaceId, placeSessionsById]);

		const deletePlaceSession = useCallback((id: number) => {
			placeSessionDispatch({
				action: 'delete',
				object: {id} as PlaceSession,
			});
		}, []);

		const updatePlaceSession = useCallback((placeSession: PlaceSession) => {
			placeSessionDispatch({
				action: 'update',
				object: placeSession,
			});
		}, []);

		const savePlaceSession = useCallback(async (placeSession: PlaceSession, endSession: boolean = false) => {
			const response = await postSavePlaceSession({
				id: placeSession.id,
				external_id: placeSession.externalId,
				place_id: placeSession.place.id,
				start_date: placeSession.startDate ?? Date.now(),
				end_date: endSession ? Date.now() : placeSession.endDate,
				number_of_covers: placeSession.numberOfCovers,
				menu_card_id: placeSession.menuCardIds[0],
			});

			if (response.endDate === undefined)
				updatePlaceSession(response);
			else
				deletePlaceSession(response.id);

			return response;
		}, [deletePlaceSession, updatePlaceSession]);

		useWebSocketQueryRxjs<PlaceSessionStartDateMutationEvent>(
			new ManagementQuery(businessId),
			'PlaceSessionStartDateMutationEvent',
			async mutation =>
			{
				const placeSession = await fetch(
					`/waiter/business/place/sessions/${mutation.placeSessionId}`,
					undefined,
					PlaceSessionProfile,
				);

				const place = getPlaceById(placeSession.place.id);

				if (place !== undefined && placeSession.endDate === undefined)
				{
					updatePlaceSession(placeSession);
				}
			},
		);

		useWebSocketQueryRxjs<PlaceSessionNumberOfCoversMutationEvent>(
			new ManagementQuery(businessId),
			'PlaceSessionNumberOfCoversMutationEvent',
			async mutation =>
			{
				const placeSession = await fetch(
					`/waiter/business/place/sessions/${mutation.placeSessionId}`,
					undefined,
					PlaceSessionProfile,
				);

				const place = getPlaceById(placeSession.place.id);

				if (place !== undefined && placeSession.endDate === undefined)
				{
					updatePlaceSession(placeSession);
				}
			},
		);

		useWebSocketQueryRxjs<PlaceSessionEndDateMutationEvent>(
			new ManagementQuery(businessId),
			'PlaceSessionEndDateMutationEvent',
			mutation => {
				const place = getPlaceById(mutation.placeId);

				if (place !== undefined)
				{
					const placeSession = placeSessionsById?.[mutation.placeSessionId];

					if (placeSession !== undefined)
					{
						placeSession.place = place;

						placeSession.endDate = mutation.toValue === undefined
							? undefined
							: new Date(mutation.toValue).getTime();

						if (placeSession.endDate === undefined)
							updatePlaceSession(placeSession);
						else
							deletePlaceSession(placeSession.id);
					}
				}
			},
		);

		return <ContextRef.Provider value={useMemo<Context>(() => ({
			getPlaceSessionByPlaceId,
			savePlaceSession,
		}), [getPlaceSessionByPlaceId, savePlaceSession])}>
			{children}
		</ContextRef.Provider>;
	};

export function useManagerPlaceSessionsContext(): Context
{
	return useContext(ContextRef);
}