import { Place } from '../Business/Place';
import { AndStoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/AndStoryVisibilityPredicate';
import { NotStoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/NotStoryVisibilityPredicate';
import { OrStoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/OrStoryVisibilityPredicate';
import { PlaceRangeStoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/PlaceRangeVisibilityPredicate';
import { PlaceStoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/PlaceVisibilityPredicate';
import { StoryVisibilityPredicate } from '../v3/model/story/visibility_predicate/StoryVisibilityPredicate';
import { TimeScheduleVisibilityPredicate } from '../v3/model/story/visibility_predicate/TimeScheduleVisibilityPredicate';
import { TimeSchedule } from './time-schedule/TimeSchedule';
import { evaluateTimeSchedule } from './time/evaluateTimeSchedule';

const NaturalSortRegex = /\d+|\D+/g;

function NaturalSortComparator(lhs: string[], rhs: string[]): number
{
	const maxIndex = Math.min(lhs.length, rhs.length);

	for (let i = 0; i < maxIndex; i++)
	{
		const from = lhs[i];
		const to = rhs[i];

		if (from !== to)
		{
			const fromNumber = parseInt(from);
			const toNumber = parseInt(to);

			if (isNaN(fromNumber) || isNaN(toNumber))
				return from.localeCompare(to);
			else
				return fromNumber - toNumber;
		}
	}

	return lhs.length - rhs.length;
}

/**
 * evaluate if a time schedule is true for a given {@code targetDate}, when evaluating it at {@code date}
 * @param date the date of the evaluation. (Often "now").
 * @param place the place where it should be evaluated.
 * @param timeScheduleByUuid the timeSchedule by id map where storyVisibilityPredicate should be evaluated.
 * @param visibilityPredicate the visibility predicate that should be evaluated.
 */
export function evaluateStoryVisibilityPredicate(
	date: Date,
	place: Place,
	timeScheduleByUuid: Map<string, TimeSchedule>,
	visibilityPredicate: StoryVisibilityPredicate,
): boolean
{
	switch (visibilityPredicate.type)
	{
		case 'Always':
			return true;

		case 'And':
			return evaluateAndVisibilityPredicate(date, place, timeScheduleByUuid, visibilityPredicate as AndStoryVisibilityPredicate);

		case 'Or':
			return evaluateOrVisibilityPredicate(date, place, timeScheduleByUuid, visibilityPredicate as OrStoryVisibilityPredicate);

		case 'Not':
			return evaluateNotVisibilityPredicate(date, place, timeScheduleByUuid, visibilityPredicate as NotStoryVisibilityPredicate);

		case 'Place':
			return evaluatePlaceVisibilityPredicate(place, visibilityPredicate as PlaceStoryVisibilityPredicate);

		case 'PlaceRange':
			return evaluatePlaceRangeVisibilityPredicate(place, visibilityPredicate as PlaceRangeStoryVisibilityPredicate);

		case 'TimeSchedule':
			return evaluateTimeScheduleVisibilityPredicate(date!, timeScheduleByUuid, visibilityPredicate as TimeScheduleVisibilityPredicate);

		default:
			throw new Error(`Unknown visibility predicate: ${visibilityPredicate}`);
	}
}

function evaluateAndVisibilityPredicate(
	date: Date,
	place: Place,
	timeScheduleByUuid: Map<string, TimeSchedule>,
	andStoryVisibilityPredicate: AndStoryVisibilityPredicate,
): boolean
{
	return andStoryVisibilityPredicate
		.predicates
		.every(predicate =>
			evaluateStoryVisibilityPredicate(
				date,
				place,
				timeScheduleByUuid,
				predicate,
			),
		);
}

function evaluateOrVisibilityPredicate(
	date: Date,
	place: Place,
	timeScheduleByUuid: Map<string, TimeSchedule>,
	orStoryVisibilityPredicate: OrStoryVisibilityPredicate,
): boolean
{
	return orStoryVisibilityPredicate
		.predicates
		.some(predicate =>
			evaluateStoryVisibilityPredicate(
				date,
				place,
				timeScheduleByUuid,
				predicate,
			),
		);
}

function evaluateNotVisibilityPredicate(
	date: Date,
	place: Place,
	timeScheduleByUuid: Map<string, TimeSchedule>,
	notStoryVisibilityPredicate: NotStoryVisibilityPredicate,
): boolean
{
	return !evaluateStoryVisibilityPredicate(date, place, timeScheduleByUuid, notStoryVisibilityPredicate.predicate);
}

function evaluatePlaceVisibilityPredicate(
	place: Place,
	placeStoryVisibilityPredicate: PlaceStoryVisibilityPredicate,
): boolean
{
	return place?.uuid === placeStoryVisibilityPredicate.placeId;
}

function evaluatePlaceRangeVisibilityPredicate(
	place: Place,
	placeRangeStoryVisibilityPredicate: PlaceRangeStoryVisibilityPredicate,
): boolean
{
	return isPlaceInRange(place.name, placeRangeStoryVisibilityPredicate);
}

function evaluateTimeScheduleVisibilityPredicate(
	date: Date,
	timeScheduleByUuid: Map<string, TimeSchedule>,
	timeScheduleStoryVisibilityPredicate: TimeScheduleVisibilityPredicate,
): boolean
{
	const timeSchedule = timeScheduleByUuid.get(timeScheduleStoryVisibilityPredicate.timeScheduleId);

	if (timeSchedule)
	{
		return evaluateTimeSchedule(date, date, timeSchedule.booleanTimeSeries);
	}
	else
	{
		throw new Error(`Unknown time schedule predicate with uuid: ${timeScheduleStoryVisibilityPredicate.timeScheduleId}`);
	}
}

function isPlaceInRange(placeName: string, placeRangeStoryVisibilityPredicate: PlaceRangeStoryVisibilityPredicate)
{
	if (placeName === '')
	{
		return placeRangeStoryVisibilityPredicate.from === '';
	}

	const nameParts = placeName.match(NaturalSortRegex)!;

	let inRange = true;

	if (placeRangeStoryVisibilityPredicate.from !== '')
	{
		const fromParts = placeRangeStoryVisibilityPredicate.from.match(NaturalSortRegex)!;

		inRange = inRange && NaturalSortComparator(nameParts, fromParts) >= 0
	}
	else if (placeRangeStoryVisibilityPredicate.to === '')
	{
		return false;
	}

	const toParts = placeRangeStoryVisibilityPredicate.to.match(NaturalSortRegex)!;

	inRange = inRange &&  NaturalSortComparator(nameParts, toParts) <= 0;

	return inRange;
}